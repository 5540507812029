import * as actionsType from "./actionsType";
import ReservationService from "../services/ReservationService";

export const getRelatedArticles = data => async dispatch => {
  dispatch(removeRelatedArticles());
  const response = await ReservationService.getRelatedArticles(data);
  dispatch(setRelatedArticles(response));
};

export const removeRelatedArticles = data => async dispatch => {
  dispatch(removeArticles());
};

const setRelatedArticles = payload => ({
  type: actionsType.SET_RELATED_ARTICLES,
  payload: payload
});

const removeArticles = payload => ({
  type: actionsType.REMOVE_RELATED_ARTICLES,
  payload: payload
});
