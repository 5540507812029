import * as actionsType from "./actionsType";
import { push } from "connected-react-router";
import * as path from "./../../common/routeTable";
import CartService from "../services/CartService";

export const createCart = (data) => async (dispatch) => {
  dispatch(setLoading());
  const response = await CartService.createCart(data);
  dispatch(setCart(response));
  dispatch(setInstitute(data.instituteId));
  dispatch(push(path.Summary));
};

export const getCart = (data) => async (dispatch) => {
  dispatch(setLoading());
  const response = await CartService.getCart(data);
  dispatch(setCart(response));
};

export const addItemToCart = (data) => async (dispatch) => {
  const response = await CartService.addItemtoCart(data);
  dispatch(setCart(response));
  dispatch(push(path.Summary));
};

export const removeItemFromCart = (data) => async (dispatch) => {
  const response = await CartService.removeItemFromCart(data);
  if (response.items.length <= 0) {
    dispatch(removeCart(data.instituteId));
  } else {
    dispatch(setCart(response));
  }
};

export const confirmCart = (data) => async (dispatch) => {
  const response = await CartService.confirmCart(data);
  if (response) {
    dispatch(removeCart());
    dispatch(push(path.ThankYou));
  }
};

export const removeNotFoundCart = (data) => async (dispatch) => {
  dispatch(removeCart());
};

const setCart = (payload) => ({
  type: actionsType.SET_CART,
  payload: payload,
});

const setLoading = () => ({
  type: actionsType.GET_CART,
  payload: {},
});

const setInstitute = (payload) => ({
  type: actionsType.SET_CARTS_INSTITUTE,
  payload: payload,
});

const removeCart = (payload) => ({
  type: actionsType.REMOVE_CART,
  payload: payload,
});
