import React from "react";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import { Field } from "redux-form";
import * as validate from "./../../../../common/validate";
import { CustomInput } from "../../../UI/CustomInput";

const maxLengthMapUrl = validate.maxLength(512);
const maxLengthPhone = validate.maxLength(20);
const maxLengthEmail = validate.maxLength(100);
const maxLengthWebsiteUrl = validate.maxLength(256);

export const CardContact = () => {
  return (
    <Card style={{ height: "100%" }}>
      <CardHeader component="h2" title="Kontakt" />
      <CardContent>
        <Field
          name="contact.firstPhone"
          component={CustomInput}
          placeholder="Telefon 1"
          validate={[validate.required, maxLengthPhone]}
        />
        <Field
          name="contact.secondPhone"
          component={CustomInput}
          placeholder="Telefon 2"
          validate={[maxLengthPhone]}
          normalize={validate.normalizeNotRequiredField}
        />
        <Field
          name="contact.email"
          component={CustomInput}
          placeholder="Email"
          validate={[validate.emailFormat, maxLengthEmail]}
          normalize={validate.normalizeNotRequiredField}
        />

        <Field
          name="contact.websiteUrl"
          component={CustomInput}
          placeholder="www"
          validate={[maxLengthWebsiteUrl]}
          normalize={validate.normalizeNotRequiredField}
        />
        <Field
          name="address.mapUrl"
          component={CustomInput}
          placeholder="Url mapy"
          validate={[maxLengthMapUrl, validate.required]}
        />
      </CardContent>
    </Card>
  );
};
