import React from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";

export const SummaryButton = ({ className, submitting, size = 20, icon, children, id, ...rest }) => {
  return (
    <button
      className={"button" + className === undefined ? "" : " " + className}
      id={id}
      disabled={submitting}
      variant="contained"
      color="primary"
      position={{ position: "relative" }}
      {...rest}
    >
      {submitting ? (
        <CircularProgress
          className={id}
          color="inherit"
          size={size}
          thickness={7}
          style={{
            display: !submitting ? "none" : "inline-block",
            position: "absolute",
            right: "0",
            top: `calc(50% - ${size / 2}px)`,
            left: `calc(50% - ${size / 2}px)`
          }}
        />
      ) : (
        <></>
      )}
      <span className={id} style={{ visibility: submitting ? "hidden" : "visible", maxWidth: "100%" }}>
        {icon ? <>{icon}&nbsp;</> : <></>}
        {children}
      </span>
    </button>
  );
};

SummaryButton.propTypes = {
  className: PropTypes.string,
  submitting: PropTypes.bool,
  size: PropTypes.number,
  id: PropTypes.string
};
