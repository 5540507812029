import * as errorsTypes from "./../../common/errorsType";
import { errorCreator, errorFieldsCreator } from "../../common/errorCreator";
import axios from "axios";
import { getToken } from "./../selectors";
import { logout, refreshToken } from "./../actions/authorizeAction";
import { stateManagment } from "./../../components/App";

const apiService = axios.create({
  baseURL: "/api",
  headers: {
    Pragma: "no-cache",
  },
});

apiService.interceptors.response.use(
  (response) => {
    if (response.status === 204) {
      errorCreator(errorsTypes.NOT_FOUND, "");
    }
    return response;
  },
  async (error) => {
    const store = stateManagment.store;
    const token = getToken(store.getState());
    const originalRequest = error.config;

    if (error.response === undefined || error.response == null) {
      throw error;
    }
    if (error.response.status === 401) {
      if (error.response.config.url === "/tokens" && error.response.config.method === "patch") {
        await store.dispatch(logout());
      }

      if (token.accessToken === null || token.accessToken === undefined) {
        errorCreator(errorsTypes.UNATHORIZED, "Niepoprawne dane logowania");
        await store.dispatch(logout());
      } else {
        if (token.isAuthorize) {
          await store.dispatch(refreshToken({ refreshToken: token.refreshToken }));
        }

        const afterRefreshToken = getToken(store.getState());
        const retryOrigReq = new Promise(async (resolve, reject) => {
          originalRequest.headers["Authorization"] = "Bearer " + afterRefreshToken.accessToken;
          await resolve(axios(originalRequest));
        });
        return retryOrigReq;
      }
    }
    if (error.response.status === 404) {
      errorCreator(errorsTypes.NOT_FOUND, error.response.data.details);
    }

    if (error.response.status === 400) {
      if (error.response.data.details && error.response.data.details[0].message === "OverlappedReservations") {
        errorCreator(errorsTypes.OVERLAPPED_HOURS, error.response.data.details);
      } else if (Array.isArray(error.response.data.details)) {
        errorFieldsCreator(errorsTypes.FORM_FIELDS, error.response.data.details);
      }

      errorCreator(errorsTypes.BAD_REQUEST, error.response.data.details);
    }

    throw error;
  }
);

export default apiService;
