export const scrollToInvalid = errors => {
  if (errors) {
    let nameField = getNameField(errors);
    if (window.scroll !== undefined) {
      window.scroll({
        top: window.pageYOffset + (document.getElementsByName(nameField)[0].getBoundingClientRect().top - 100),
        behavior: "smooth"
      });
    }
  }
};

function getNameField(field) {
  if (typeof field === "object") {
    for (let insideField in field) {
      let namePartField = getNameField(field[insideField]);
      if (namePartField === "$$typeof") {
        namePartField = "";
      }
      return insideField + (namePartField === 0 || namePartField.length === 0 ? "" : ".") + namePartField;
    }
  }
  return "";
}
