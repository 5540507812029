import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FormControl, FormHelperText } from "@material-ui/core";

function getBase64(file, onChange) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function() {
    onChange(reader.result.replace(/^data:image\/[a-z]+;base64,/, ""));
  };
  reader.onerror = function(error) {
    console.log("Error: ", error);
  };
}

function Previews({ input, touched, error, placeholder }) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    accept: "image/*",
    maxSize: 2097152,
    onDrop: acceptedFiles => {
      if (acceptedFiles[0] === undefined) {
        return;
      }
      getBase64(acceptedFiles[0], input.onChange);

      let file = acceptedFiles.shift();

      return setFiles([
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      ]);
    }
  });

  const thumbs = files.map((file, key) => (
    <div className="dropzone__thumb" key={key}>
      <div className="dropzone__thumbInner">
        <img src={file.preview} className="dropzone__thumbImage" />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      files.forEach(file => {
        return URL.revokeObjectURL(file.preview);
      });
    },
    [files]
  );

  useEffect(() => {
    if (input.value === undefined || input.value.length === 0) {
      setFiles([]);
      return;
    }
    setFiles([{ preview: "data:image/png;base64," + input.value }]);
  }, [input]);

  return (
    <section className="dropozone__inner" name={input.name}>
      <div
        {...getRootProps({
          className:
            "dropzone" +
            (isDragActive ? " dropzone__border--dark" : "") +
            ((touched && error !== undefined) || isDragReject ? " dropzone__border--red" : "")
        })}
      >
        <input {...getInputProps()} />
        <p>{placeholder}</p>
      </div>
      {touched && error !== undefined ? (
        <FormHelperText style={{ fontSize: "0.75rem" }}>{error}</FormHelperText>
      ) : (
        <></>
      )}
      {isDragReject ? (
        <FormHelperText style={{ fontSize: "0.75rem" }} error>
          Niepoprawny plik
        </FormHelperText>
      ) : (
        <></>
      )}

      <aside className="dropzone__container">{thumbs}</aside>
    </section>
  );
}

export const CustomFileUpload = ({ input, meta: { touched, error }, placeholder }) => {
  return (
    <FormControl variant="outlined" error={touched && error !== undefined} style={{ width: "100%" }}>
      <Previews input={input} touched={touched} error={error} placeholder={placeholder} />
    </FormControl>
  );
};
