import React from "react";
import { withLocalize, Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { push, replace } from "connected-react-router";
import * as path from "../../../common/routeTable";
import moment from "moment";
import "moment/locale/pl";
import { removeItemFromCart } from "../../../state/actions/cartAction";
import { CircularProgress } from "@material-ui/core";

const CartSummary = ({ activeLanguage }) => {
  moment.locale(activeLanguage.code);
  const dispatch = useDispatch();
  const state = useSelector((state) => state.cart);
  const isCartSummary = window.location.pathname.includes(path.Summary);
  let isBackHisotrySet = false;
  // if(state.instituteId){
  //   window.history.replaceState(null, '', path.ArticleGroups + `?instituteId=${state.instituteId}`);
  //   window.history.pushState(null, '', path.Summary);
  // }
  const handleUrlClick = (e) => {
    try {
      e.preventDefault();
    } catch (err) {
      //do nothing
    }
    dispatch(push(path.ArticleGroups + `?instituteId=${state.instituteId}`));
  };

  const handleNoItemsUrlClick = e => {
    try {
      e.preventDefault();
    } catch (err) {
      //do nothing
    }
    if(state.instituteId == null){
      dispatch(push(path.RootPage));
    }
    else{
      dispatch(push(path.ArticleGroups + `?instituteId=${state.instituteId}`));
    }
  };

  const handleDeleteButtonClick = itemId => {
    let data = {
      instituteId: state.instituteId,
      lang: activeLanguage.code,
      cartId: state.cart.id,
      itemId: itemId
    };
    if(!document.referrer.includes(path.Summary) && state.instituteId != null && !isBackHisotrySet){
      window.history.replaceState(null, '', path.ArticleGroups + `?instituteId=${state.instituteId}`);
      window.history.pushState(null, '', path.Summary);
      isBackHisotrySet = !isBackHisotrySet;
    }
    dispatch(removeItemFromCart(data));
  };

  return (
    <div className="summary__cartSummaryWrapper">
      {isCartSummary ? (
        <h1 className="summary__title">
          <Translate id="summary.title" />
        </h1>
      ) : null}
      <div className="summary__cartEntriesWrapper">
        {state.loading ? (
          <div className="summary__progressWrapper">
            <CircularProgress size={50} height={50} className="circularProgress--dark summary__progress" />
          </div>
        ) : state.cart.items.length === 0 ? (
          <>
            <h6 className="summary__noItems">
              <Translate id="summary.no_items" />
            </h6>
            <div className="summary__addAnotherItemWrapper">
              <a
                className="summary__addAnotherItemUrl"
                href={path.ArticleGroups}
                onClick={(e) => handleNoItemsUrlClick(e)}
              >
                <Translate id="summary.add_first" />
              </a>
            </div>
          </>
        ) : (
          <>
            {state.cart.items.map((el) => {
              return (
                <div className="summary__cartEntry" key={el.id}>
                  <div className="summary__cartEntryDetailsWrapper">
                    <div className="summary__cartEntryNameWrapper">
                      <p className="summary__cartEntryName">{el.name}</p>
                    </div>
                    <div className="summary__cartEntryDateWrapper">
                      <p className="summary__cartEntryDate">{moment(el.date).format("D MMMM YYYY")}</p>
                    </div>
                    <div className="summary__cartEntryHourWrapper">
                      <p className="summary__cartEntryHour">
                        {el.hour} - {moment(el.hour, "HH:mm").add(el.duration, "minutes").format("HH:mm")}
                      </p>
                    </div>
                    <div className="summary__cartEntryPriceHourStaffWrapper">
                      <p className="summary__cartEntryPrice">
                        {el.price} <Translate id="article_details.currency" />
                      </p>
                      <p className="summary__cartEntryEmployee">
                        {el.staffMemberFirstName} {el.staffMemberLastName}
                      </p>
                    </div>
                  </div>
                  <div className="summary__removeEntryFromCartButtonWrapper">
                    <button
                      className="summary__removeEntryFromCartButton"
                      onClick={() => handleDeleteButtonClick(el.id)}
                    >
                      x
                    </button>
                  </div>
                </div>
              );
            })}

            {isCartSummary ? (
              <>
                <div className="summary__totalPaymentWrapper">
                  <p className="summary__totalPaymentLabel">
                    <Translate id="summary.total" />
                  </p>
                  <p className="summary__totalPayment">
                    {state.cart.total} <Translate id="article_details.currency" />
                  </p>
                </div>

                <div className="summary__addAnotherItemWrapper">
                  <a
                    className="summary__addAnotherItemUrl"
                    href={path.ArticleGroups}
                    onClick={(e) => handleUrlClick(e)}
                  >
                    <Translate id="summary.add_another" />
                  </a>
                </div>
              </>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};
export default withLocalize(CartSummary);
