import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withLocalize, Translate } from "react-localize-redux";
import ReservationService from "../../../state/services/ReservationService";
import { CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getArticleDetails } from "../../../state/actions/articleDescriptionAction";

const ArticleDescription = ({ instituteId, articleId, activeLanguage }) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.articleDescription);

  useEffect(() => {
    dispatch(
      getArticleDetails({
        instituteId: instituteId,
        articleId: articleId,
        lang: activeLanguage.code
      })
    );
  }, [instituteId, articleId]);

  return (
    <div className="articleDetails__articleDescription">
      {state.loading || !state.details ? (
        <div className="circularProgress circularProgress--medium">
          <CircularProgress size={50} height={50} className="circularProgress--dark" />
        </div>
      ) : (
        <>
          <h2 className="articleDetails__subtitle articleDetails__subtitle--articleDescription">
            {state.details.name}
          </h2>
          <div className="articleDetails__text articleDetails__text--articleDescription">
            {state.details.description}
          </div>
          <div className="articleDetails__divider" />
          <div className="articleDetails__text--articleDescription articleDetails__price">
            <Translate id="article_details.price" /> {state.details.price} <Translate id="article_details.currency" /> (
            {state.details.duration} min)
          </div>
        </>
      )}
    </div>
  );
};

export default withLocalize(ArticleDescription);
