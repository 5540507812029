import * as actionsType from "../actions/actionsType";
import { updateObject } from "../utils/updateObject";

const initialState = {
  details: undefined,
  loading: true
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionsType.SET_DETAILS:
      return setDetails(state, payload);
    case actionsType.REMOVE_DETAILS:
      return removeDetails(state);
    default:
      return state;
  }
};

const setDetails = (state, payload) => {
  return updateObject(state, { details: payload, loading: false });
};

const removeDetails = state => {
  return updateObject(state, initialState);
};
