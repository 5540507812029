import React from "react";
import { withLocalize, Translate } from "react-localize-redux";
import { ReactComponent as PlLogo } from "../../../images/logo-pl.svg";
import { ReactComponent as EnLogo } from "../../../images/logo-en.svg";
import { Grid } from "@material-ui/core";
import moment from "moment";

const Footer = ({ activeLanguage }) => {
  return (
    <footer className="mainFooter">
      <Grid container>
        {activeLanguage.code === "pl" ? (
          <PlLogo className="mainFooter__svgLogo" />
        ) : (
          <EnLogo className="mainFooter__svgLogo" />
        )}
      </Grid>
      <Grid container className="mainFooter__gridWrapper">
        <div className="mainFooter__infoWrapper">
          <p className="mainFooter__addressLine">Dr Irena Eris S.A.</p>
          <p className="mainFooter__addressLine">ul. Armii Krajowej 12</p>
          <p className="mainFooter__addressLine">05-500 Piaseczno</p>
          <p className="mainFooter__addressLine">
            <a
              href="mailto:instytuty.biuro@DrIrenaEris.com"
              className="mainFooter__addressUrl"
            >
              Instytuty.biuro@DrIrenaEris.com
            </a>
          </p>
        </div>
        <div className="mainFooter__infoWrapper mainFooter__infoWrapper--right">
          <p className="mainFooter__url mainFooter__url--header">
            <Translate id="footer.useful_resources_uppercase" />
          </p>
          <p className="mainFooter__url">
            <a
              href={`${process.env.PUBLIC_URL}/files/regulamin.pdf`}
              target="_blank"
              className="mainFooter__addressUrl"
            >
              <Translate id="footer.regulations_uppercase" />
            </a>
          </p>
          <p className="mainFooter__url">
            <a
              href={`${process.env.PUBLIC_URL}/files/pp.pdf`}
              target="_blank"
              className="mainFooter__addressUrl"
            >
              <Translate id="footer.privacy_policy_uppercase" />
            </a>
          </p>
          <p className="mainFooter__url">
            <a
              href="https://www.instytuty.drirenaeris.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="mainFooter__addressUrl"
            >
              <Translate id="footer.eris_homepage_uppercase" />
            </a>
          </p>
        </div>
      </Grid>
      <Grid container>
        <p className="mainFooter__copyright">COPYRIGHT © {moment().format("YYYY")} DR IRENA ERIS</p>
      </Grid>
    </footer>
  );
};

export default withLocalize(Footer);
