import * as actionTypes from "./actionsType";

export const showError = errorMessage => dispatch => {
  dispatch(setShowError(errorMessage));
};

export const closeMessageModal = () => {
  return {
    type: actionTypes.CLOSE_MESSAGE_MODAL
  };
};

export const showMessageModal = message => {
  return {
    type: actionTypes.SHOW_MESSAGE_MODAL,
    message: message
  };
};

const setShowError = errorMessage => {
  return {
    type: actionTypes.SHOW_ERROR,
    message: errorMessage
  };
};
