import React from "react";
import { withLocalize } from "react-localize-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import PropTypes from "prop-types";

const Suspender = ({ idPrefix, count = 4 }) => {
  return (
    <div className="skeletonWrapper" key={idPrefix}>
      {Array.from({ length: count }, (value, index) => (
        <Skeleton className="skeletonWrapper__buttonSkeleton" key={idPrefix + "-" + index} />
      ))}
    </div>
  );
};

Suspender.propTypes = {
  idPrefix: PropTypes.any,
  count: PropTypes.number
};

Suspender.defaultProps = {
  idPrefix: undefined,
  count: 4
};

export default withLocalize(Suspender);
