import React, { useEffect } from "react";
import { reduxForm, Field } from "redux-form";
import { useDispatch } from "react-redux";
import { Container, Grid } from "@material-ui/core";
import { CustomInput } from "./../../UI/CustomInput";
import { CustomButton } from "../../UI/CustomButton";
import * as validate from "./../../../common/validate";
import { login } from "./../../../state/actions/authorizeAction";
import submitForm from "./../../../state/utils/submitForm";
import { withLocalize } from "react-localize-redux";
import PropTypes from "prop-types";

const Login = ({ handleSubmit, submitting, setActiveLanguage }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    setActiveLanguage("pl");
  }, []);

  return (
    <div className="login">
      <div className="login__inner">
        <div className="login__header">
          <h2 className="login__headerTitle">Logowanie</h2>
        </div>
        <div className="login__card">
          <div className="login__content">
            <form onSubmit={handleSubmit(values => submitForm(dispatch(login(values)), dispatch))}>
              <Container>
                <Grid>
                  <Field name="email" component={CustomInput} placeholder="Login" validate={[validate.required]} />
                </Grid>
                <Grid>
                  <Field
                    name="password"
                    component={CustomInput}
                    type="password"
                    placeholder="Hasło"
                    validate={[validate.required]}
                  />
                </Grid>
                <Grid className="login__contentSender">
                  <CustomButton className="button__login" type="submit" submitting={submitting}>
                    Zaloguj
                  </CustomButton>
                </Grid>
              </Container>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withLocalize(reduxForm({ form: "loginForm" })(Login));

Login.propTypes = {
  setActiveLanguage: PropTypes.func.isRequired
};
