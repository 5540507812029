import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Grid, CircularProgress } from "@material-ui/core";
import { ArrowForwardIos, ArrowBackIos } from "@material-ui/icons";
import { Translate, withLocalize } from "react-localize-redux";
import PropTypes from "prop-types";
import "moment/locale/pl";
import ReservationService from "../../../state/services/ReservationService";
import ArticleDetails from "./ArticleDetails";
import { useDispatch, useSelector } from "react-redux";
import { createCart, addItemToCart } from "../../../state/actions/cartAction";
import { showError } from "../../../state/actions/messageModalAction";
import { Summary } from "../../../common/routeTable";
import { push } from "connected-react-router";
import { overlapChecker } from "../OverlapChecker";

const DatePicker = ({ activeLanguage, CanIterateBack, disabledDays, instituteId, articleId }) => {
  moment.locale(activeLanguage.code);
  const dispatch = useDispatch();
  const state = useSelector((state) => state.cart);
  const [today, setToday] = useState(moment());
  const [stateWeekDays, setStateWeekDays] = useState([]);
  const [iterator, setIterator] = useState(0);
  const [monthName, setMonthName] = useState(moment().format("MMMM"));
  const [year, setYear] = useState(moment().format("YYYY"));
  const [selectedDate, setSelectedDate] = useState(moment());
  const [allHours, setAllHours] = useState([]);
  const [morningHours, setMorningHours] = useState([]);
  const [afternoonHours, setAfternoonHours] = useState([]);
  const [eveningHours, setEveningHours] = useState([]);
  const [hourLoading, setHourLoading] = useState(true);
  const [selectedHour, setSelectedHour] = useState(null);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [employeeLoading, setEmployeeLoading] = useState(true);
  const [employeeListIndex, setEmployeeListIndex] = useState(0);
  const [articleDetails, setArticleDetails] = useState({});
  const [articleDetailsLoading, setArticleDetailsLoading] = useState(true);

  useEffect(() => {
    let weekDays = getWeekDays(iterator);
    setStateWeekDays(weekDays);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iterator]);

  const getWeekDays = (weekIterator) => {
    let firstDay = today.add(weekIterator, "week").startOf("isoWeek").add(-1, "d");
    let weekDays = [];

    for (let i = 0; i < 7; i++) {
      weekDays[i] = moment(firstDay.add(1, "d"));
    }

    setToday(moment());
    let monthName = moment(weekDays[0]).format("MMMM");
    let year = moment(weekDays[0]).format("YYYY");
    if (moment(weekDays[6]).format("MMMM") !== monthName) {
      monthName = monthName + "/" + moment(weekDays[6]).format("MMMM");
    }
    if (moment(weekDays[6]).format("YYYY") !== year) {
      year = year + "/" + moment(weekDays[6]).format("YYYY");
    }
    setMonthName(monthName);
    setYear(year);
    return weekDays;
  };

  const increaseWeek = () => {
    let i = iterator + 1;
    setIterator(i);
  };

  const decreaseWeek = () => {
    let i = iterator - 1;
    setIterator(i);
  };

  const handleDateButtonClick = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    const fetchData = async () => {
      let response = await ReservationService.getArticleDetails({
        instituteId: instituteId,
        articleId: articleId,
        lang: activeLanguage.code,
      });

      setArticleDetails(response);
      setArticleDetailsLoading(false);
    };

    if (articleId) {
      fetchData();
    }
  }, [articleId]);

  useEffect(() => {
    const fetchData = async () => {
      let response = await ReservationService.getArticlesAvailableHours({
        instituteId: instituteId,
        articleId: articleId,
        lang: activeLanguage.code,
        date: selectedDate.format("YYYY-MM-DD"),
      });

      let mH = [];
      let aH = [];
      let eH = [];

      setAllHours(response.availableHours);

      response.availableHours.map((hour) => {
        if (parseInt(hour.split(":")[0]) < 12) {
          mH.push(hour);
        } else if (parseInt(hour.split(":")[0]) < 17) {
          aH.push(hour);
        } else {
          eH.push(hour);
        }
      });
      setMorningHours(mH);
      setAfternoonHours(aH);
      setEveningHours(eH);
      setHourLoading(false);
    };
    setSelectedHour(null);
    setSelectedEmployee(null);
    setHourLoading(true);
    setEmployeeListIndex(0);
    setEmployeeLoading(true);
    if (instituteId && articleId) {
      fetchData();
    }
  }, [instituteId, articleId, selectedDate]);

  const handleHourButtonClick = (hour) => {
    setEmployeeListIndex(0);
    setSelectedHour(hour);
  };

  useEffect(() => {
    setEmployeeLoading(true);
    if (selectedHour) {
      const fetchData = async () => {
        let response = [];
        // staffMemberId: employeeList[employeeListIndex].id,
        //   staffMemberFirstName: employeeList[employeeListIndex].firstName,
        //   staffMemberLastName: employeeList[employeeListIndex].lastName
        response[0] = {
          id: -1,
          firstName: <Translate id="select_date.employee_any" />,
          lastName: "",
        };

        let requestResponse = await ReservationService.getEmployeeList({
          instituteId: instituteId,
          articleId: articleId,
          lang: activeLanguage.code,
          date: selectedDate.format("YYYY-MM-DD"),
          hour: selectedHour,
        });
        try {
          response.push(...requestResponse);
        } catch (err) {
          //do nothing
        }

        setEmployeeList(response);
        setEmployeeLoading(false);
      };

      fetchData();
    }
  }, [selectedHour]);

  const decreaseEmployee = () => {
    if (employeeListIndex - 1 < 0) {
      setEmployeeListIndex(employeeList.length - 1);
    } else {
      setEmployeeListIndex(employeeListIndex - 1);
    }
  };

  const increaseEmployee = () => {
    if (employeeListIndex + 2 > employeeList.length) {
      setEmployeeListIndex(0);
    } else {
      setEmployeeListIndex(employeeListIndex + 1);
    }
  };

  const handleConfirmButtonClick = async () => {
    if (instituteId && articleId && selectedDate && selectedHour && !employeeLoading) {
      let data = {};
      if (employeeListIndex !== 0) {
        data = {
          instituteId: instituteId,
          lang: activeLanguage.code,
          body: {
            articleId: articleId,
            date: selectedDate.format("YYYY-MM-DD"),
            hour: selectedHour,
            staffMemberId: employeeList[employeeListIndex].id,
            staffMemberFirstName: employeeList[employeeListIndex].firstName,
            staffMemberLastName: employeeList[employeeListIndex].lastName,
          },
        };
      } else {
        data = {
          instituteId: instituteId,
          lang: activeLanguage.code,
          body: {
            articleId: articleId,
            date: selectedDate.format("YYYY-MM-DD"),
            hour: selectedHour,
          },
        };
      }
      if (state.instituteId && instituteId !== state.instituteId) {
        dispatch(showError(<Translate id="select_date.wrong_institute" />));
        dispatch(push(Summary));
      } else {
        if (state.cart.items.length > 0) {
          let overlap = overlapChecker(state.cart.items, selectedHour, articleDetails.duration, selectedDate);
          if (overlap) {
            dispatch(showError(<Translate id="select_date.overlapped_hour" />));
          } else {
            data["cartId"] = state.cart.id;
            dispatch(addItemToCart(data));
          }
        } else {
          dispatch(createCart(data));
        }
      }
    } else {
      dispatch(showError(<Translate id="select_date.required_info_not_provided" />));
    }
  };

  return (
    <div className="selectDate__datePickerWrapper">
      <Grid container justify="center" alignItems="center" direction="row" className="datePicker">
        <Grid id="month-year">
          <p className="selectDate__monthYearLabel">
            <Translate id={`select_date.months_uppercase.m${moment(stateWeekDays[0]).format("M")}`} />
            {monthName.split("/")[1] ? (
              <>
                {"/"}
                <Translate id={`select_date.months_uppercase.m${moment(stateWeekDays[6]).format("M")}`} />
              </>
            ) : (
              <></>
            )}{" "}
            {year}
          </p>
        </Grid>
        <Grid container id="week-days" justify="center" alignItems="center" direction="row">
          <div className="datePicker__weekDayGrid" id="spacer-start" />
          <Grid className="datePicker__weekDayGrid" id="monday">
            <p className="selectDate__dayName">
              <Translate id="select_date.days_uppercase.d1" />
            </p>
          </Grid>
          <Grid className="datePicker__weekDayGrid" id="tuesday">
            <p className="selectDate__dayName">
              <Translate id="select_date.days_uppercase.d2" />
            </p>
          </Grid>
          <Grid className="datePicker__weekDayGrid" id="wednesday">
            <p className="selectDate__dayName">
              <Translate id="select_date.days_uppercase.d3" />
            </p>
          </Grid>
          <Grid className="datePicker__weekDayGrid" id="thursday">
            <p className="selectDate__dayName">
              <Translate id="select_date.days_uppercase.d4" />
            </p>
          </Grid>
          <Grid className="datePicker__weekDayGrid" id="friday">
            <p className="selectDate__dayName">
              <Translate id="select_date.days_uppercase.d5" />
            </p>
          </Grid>
          <Grid className="datePicker__weekDayGrid" id="saturday">
            <p className="selectDate__dayName">
              <Translate id="select_date.days_uppercase.d6" />
            </p>
          </Grid>
          <Grid className="datePicker__weekDayGrid" id="sunday">
            <p className="selectDate__dayName">
              <Translate id="select_date.days_uppercase.d7" />
            </p>
          </Grid>
          <div className="datePicker__weekDayGrid" id="spacer-end" />
        </Grid>
        <Grid id="week-picker" className="datePicker">
          <Button
            className="datePicker__button"
            disabled={CanIterateBack ? false : iterator <= 0 ? true : false}
            onClick={() => decreaseWeek()}
          >
            <ArrowBackIos />
          </Button>
          {stateWeekDays.map((day) => {
            let disabled = !CanIterateBack
              ? day >= moment()
                ? false
                : day.format("DD.MM.YYYY") === moment().format("DD.MM.YYYY")
                ? false
                : true
              : false;
            let selected = day.format("YYYY-MM-DD") === selectedDate.format("YYYY-MM-DD");
            let className = `datePicker__button reservation__button`;
            if (disabled) {
              className = className + " datePicker__button--disabled";
            }
            if (selected) {
              className = className + " datePicker__button--selected";
            }
            return (
              <button className={className} key={day} disabled={disabled} onClick={() => handleDateButtonClick(day)}>
                {day.format("DD")}
              </button>
            );
          })}
          <Button className="datePicker__button" onClick={() => increaseWeek()}>
            <ArrowForwardIos />
          </Button>
        </Grid>
        <Grid container justify="center" direction="row" id="date-time-container">
          <div className="datePicker__dateTimeGrid">
            <p className="datePicker__timeOfDayTitle">
              <Translate id="morning_uppercase" />
            </p>
            <div className="datePicker__hourButtonGroupWrapper">
              {hourLoading ? (
                <></>
              ) : (
                morningHours.map((hour) => {
                  return (
                    <button
                      key={hour}
                      className={
                        selectedHour === hour
                          ? "reservation__button datePicker__hourButton  datePicker__activeHourButton"
                          : "reservation__button datePicker__hourButton"
                      }
                      onClick={() => handleHourButtonClick(hour)}
                    >
                      {hour}
                    </button>
                  );
                })
              )}
            </div>
          </div>
          <div className="datePicker__dateTimeGrid">
            <p className="datePicker__timeOfDayTitle">
              <Translate id="afternoon_uppercase" />
            </p>
            <div className="datePicker__hourButtonGroupWrapper">
              {hourLoading ? (
                <CircularProgress size={50} height={50} className="circularProgress--dark datePicker__progress" />
              ) : allHours.length === 0 ? (
                <p className="datePicker__noAvailableDates">
                  <Translate id="select_date.no_available_dates" />
                </p>
              ) : (
                afternoonHours.map((hour) => {
                  return (
                    <button
                      key={hour}
                      className={
                        selectedHour === hour
                          ? "reservation__button datePicker__hourButton  datePicker__activeHourButton"
                          : "reservation__button datePicker__hourButton"
                      }
                      onClick={() => handleHourButtonClick(hour)}
                    >
                      {hour}
                    </button>
                  );
                })
              )}
            </div>
          </div>
          <div className="datePicker__dateTimeGrid">
            <p className="datePicker__timeOfDayTitle">
              <Translate id="evening_uppercase" />
            </p>
            <div className="datePicker__hourButtonGroupWrapper">
              {hourLoading ? (
                <></>
              ) : (
                eveningHours.map((hour) => {
                  return (
                    <button
                      key={hour}
                      className={
                        selectedHour === hour
                          ? "reservation__button datePicker__hourButton  datePicker__activeHourButton"
                          : "reservation__button datePicker__hourButton"
                      }
                      onClick={() => handleHourButtonClick(hour)}
                    >
                      {hour}
                    </button>
                  );
                })
              )}
            </div>
          </div>
        </Grid>

        {employeeLoading || (!employeeLoading && selectedHour && employeeList.length === 1) ? (
          <div />
        ) : (
          <div className="datePicker__personWrapper">
            <div className="datePicker__personInfoCaptionWrapper selectDate__widthDiv">
              <p className="datePicker__personCaption">
                <Translate id="date_picker.person_caption" />
              </p>
            </div>
            <div className="datePicker__personPickerWrapper selectDate__widthDiv">
              <Button
                className="datePicker__button"
                disabled={employeeLoading || employeeList.length === 1}
                onClick={() => decreaseEmployee()}
              >
                <ArrowBackIos />
              </Button>

              <div className="datePicker__personsName">
                {employeeLoading ? (
                  selectedHour ? (
                    <CircularProgress size={20} className="circularProgress--dark" />
                  ) : (
                    <Translate id="date_picker.employee_loading" />
                  )
                ) : employeeListIndex === 0 ? (
                  employeeList[employeeListIndex].firstName
                ) : (
                  employeeList[employeeListIndex].firstName + " " + employeeList[employeeListIndex].lastName
                )}
              </div>
              <Button
                className="datePicker__button"
                disabled={employeeLoading || employeeList.length === 1}
                onClick={() => increaseEmployee()}
              >
                <ArrowForwardIos />
              </Button>
            </div>
            <div className="selectDate__widthDiv"></div>
          </div>
        )}

        <ArticleDetails
          loading={articleDetailsLoading}
          articleName={articleDetails.name}
          articlePrice={articleDetails.price}
          hour={selectedHour}
          articleDuration={articleDetails.duration}
        />
        <div className="datePicker__confirmButtonWrapper">
            <button className={(!selectedDate || !selectedHour || employeeLoading) ?
            "reservation__button datePicker__confirmButton--disabled" :
            "reservation__button datePicker__confirmButton"}
             disabled={(!selectedDate || !selectedHour || employeeLoading)}
             onClick={() => handleConfirmButtonClick()}>
             <Translate id="date_picker.confirm" />
           </button>
        </div>
      </Grid>
    </div>
  );
};

DatePicker.propTypes = {
  CanIterateBack: PropTypes.bool.isRequired,
  disabledDays: PropTypes.arrayOf(PropTypes.number),
};

export default withLocalize(DatePicker);
