import React from "react";
import { Translate, withLocalize } from "react-localize-redux";
import PropTypes from "prop-types";
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Articles from "./Articles";

const Groups = ({ groups, instituteId }) => {
  return (
    <div className="articleGroups__groupWrapper">
      {groups.map(group => {
        return (
          <ExpansionPanel key={group.id}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <h6 className="articleGroups__groupTitle">{group.name}</h6>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Articles groupId={group.id} instituteId={instituteId} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </div>
  );
};

Groups.propTypes = {};

export default withLocalize(Groups);
