import * as actionsType from "./actionsType";

import { logout } from "./authorizeAction";
import InstitutesPanelService from "./../services/InstitutesPanelService";
import { reset } from "redux-form";
import moment from "moment";
import { errorFieldsCreator } from "./../../common/errorCreator";
import * as errorsType from "./../../common/errorsType";
import { initialize } from "redux-form";
import { push } from "connected-react-router";
import * as path from "./../../common/routeTable";
import { showMessageModal } from "./messageModalAction";

export const getInstitutes = () => async (dispatch, getState) => {
  const token = getState()?.authorize?.accessToken;

  if (token === undefined) {
    await dispatch(logout);
    return;
  }

  const response = await InstitutesPanelService.getInstitutes(token);
  await dispatch(getReducer(actionsType.GET_INSTITUTES_PANEL, response.data));
};

export const getInstitute = id => async (dispatch, getState) => {
  await dispatch(getReducer(actionsType.SET_LOADING_DATA));
  const token = getState()?.authorize?.accessToken;

  if (token === undefined) {
    await dispatch(logout);
    return;
  }

  try {
    const institute = await InstitutesPanelService.getInstitute(token, id);

    await dispatch(getReducer(actionsType.GET_INSTITUTE, institute));
    await dispatch(initialize("instituteForm", institute));
  } catch (error) {
    await dispatch(push(path.InstitutePage));
    throw error;
  }
};

export const saveInstitute = (data, id) => async (dispatch, getStore) => {
  let store = await getStore();

  if (store?.authorize?.accessToken === undefined) {
    await dispatch(logout);
    return;
  }

  data = { ...data, openingHours: store?.institutesPanel?.openingHours || [] };

  if (id === undefined) {
    await InstitutesPanelService.createInstitute(store.authorize.accessToken, data);
    await dispatch(reset("instituteForm"));
    await dispatch(clearInsitute());
    await dispatch(showMessageModal("Instytut został dodany."));
  } else {
    await InstitutesPanelService.editIntstitute(store.authorize.accessToken, data, id);
    await dispatch(showMessageModal("Instytut został zmieniony."));
  }

  await dispatch(push(path.DashboardPage));
};

export const addOpeningHours = data => async dispatch => {
  var startTime = moment(data.to, "HH:mm a");
  var endTime = moment(data.from, "HH:mm a");
  if (!startTime.isAfter(endTime)) {
    errorFieldsCreator(errorsType.FORM_FIELDS, [{ field: "to", message: "FromFieldIsGreaterThanToField" }]);
  }

  await dispatch(getReducer(actionsType.ADD_OPENING_HOURS, { openingHours: data }));

  await dispatch(reset("cardOpeningHoursForm"));
};

export const removeOpeningHours = data => {
  return { type: actionsType.REMOVE_OPENING_HOURS, payload: data };
};

export const clearInsitute = () => async dispatch => {
  await dispatch(initialize("instituteForm", {}));
  await dispatch(getReducer(actionsType.CLEAR_INSITUTE));
};

export const getGroupDictionary = () => async (dispatch, getState) => {
  const token = getState()?.authorize?.accessToken;

  if (token === undefined) {
    dispatch(logout);
    return;
  }

  const response = await InstitutesPanelService.getGroupDictionary(token);
  await dispatch(getReducer(actionsType.GET_GROUP_DICTIONARY, response.data));
};

export const openMenu = () => {
  return {
    type: actionsType.OPEN_MENU
  };
};

export const closeMenu = () => {
  return {
    type: actionsType.ClOSE_MENU
  };
};

const getReducer = (type, payload) => ({
  type: type,
  payload
});
