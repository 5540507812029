import React from "react";
import { withLocalize, Translate } from "react-localize-redux";
import Suspender from "../../Suspender";
import PropTypes from "prop-types";
import ButtonGroup from "../ButtonGroup";

const Institutes = ({ loading, cities, articleId }) => {
  return (
    <>
      <h3 className="reservation__subtitle" id="institutes-title">
        <Translate id="reservation.institutes.title" />
      </h3>
      {loading ? (
        <Suspender idPrefix="institutes-suspender" count={7} />
      ) : (
        <ButtonGroup idPrefix="institutes" cities={cities} articleId={articleId} />
      )}
    </>
  );
};

Institutes.propTypes = {
  loading: PropTypes.bool.isRequired,
  cities: PropTypes.arrayOf(PropTypes.object),
  articleId: PropTypes.string
};

export default withLocalize(Institutes);
