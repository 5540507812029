import React, { useEffect } from "react";
import Layout from "../Layout";
import { Grid, Box, CircularProgress } from "@material-ui/core";
import { reduxForm, submit } from "redux-form";
import { CustomButton } from "./../../UI/CustomButton";
import { saveInstitute, getInstitute, clearInsitute } from "./../../../state/actions/institutesPanelAction";
import { useDispatch, useSelector } from "react-redux";
import { CardLocalData } from "./CardLocalData";
import { CardContact } from "./CardContact";
import CardOpeningHours from "./CardOpeningHours";
import submitForm from "./../../../state/utils/submitForm";
import { useParams } from "react-router-dom";
import { scrollToInvalid } from "./../../../common/scrollToInput";

const ManagmentInstitute = ({ handleSubmit, submitting }) => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state?.institutesPanel?.loading);
  const information = useSelector(state => state.institutesPanel.informationInstitute);
  const { id } = useParams();

  useEffect(() => {
    if (id === undefined) {
      dispatch(clearInsitute());
      return;
    }

    dispatch(getInstitute(id));
  }, [id]);

  if (id !== undefined && (loading === true || loading === undefined)) {
    return (
      <Layout>
        <div className="circularProgress circularProgress--medium">
          <CircularProgress size={50} height={50} className="circularProgress--dark" />
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <h2 className="dashboard__header">
        {id === undefined ? "Nowy instytut" : `Edycja instytutu id: ${information?.externalId}`}
      </h2>
      <form onSubmit={values => handleSubmit(values)}>
        <Grid container spacing={1} alignItems="stretch">
          <Grid item lg={6} xs={12}>
            <CardLocalData />
          </Grid>
          <Grid item lg={6} xs={12}>
            <CardContact />
          </Grid>
        </Grid>
      </form>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CardOpeningHours />
        </Grid>
      </Grid>
      <Box className="dashboard__cardSender">
        <CustomButton
          className="button__saveInstitute"
          type="submit"
          submitting={submitting}
          onClick={() => dispatch(submit("instituteForm"))}
        >
          {id === undefined ? "Dodaj" : "Zapisz"}
        </CustomButton>
      </Box>
    </Layout>
  );
};

function submitIntitute(values, dispatch, route) {
  return submitForm(dispatch(saveInstitute(values, route.match.params.id)), dispatch);
}

export default reduxForm({
  form: "instituteForm",
  onSubmit: submitIntitute,
  onSubmitFail: errors => scrollToInvalid(errors)
})(ManagmentInstitute);
