//Message modal
export const SHOW_ERROR = "SHOW_ERROR";
export const SHOW_MESSAGE_MODAL = "SHOW_MESSAGE_MODAL";
export const CLOSE_MESSAGE_MODAL = "CLOSE_MESSAGE_MODAL";

//Authentication
export const SUCCES_LOGIN = "SUCCES_LOGIN";
export const LOGOUT = "LOGOUT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

//Institutes Panel
export const GET_INSTITUTES_PANEL = "GET_INSTITUTES_PANEL";
export const GET_INSTITUTE = "GET_INSTITUTE";
export const ADD_OPENING_HOURS = "ADD_OPENING_HOURS";
export const REMOVE_OPENING_HOURS = "REMOVE_OPENING_HOURS";
export const CLEAR_INSITUTE = "CLEAR_INSITUTE";
export const GET_GROUP_DICTIONARY = "GET_GROUP_DICTIONARY";
export const SET_LOADING_DATA = "SET_LOADING_DATA";
export const OPEN_MENU = "OPEN_MENU";
export const ClOSE_MENU = "ClOSE_MENU";

//Reservations
export const GET_INSTITUTES_LIST = "GET_INSTITUTES_LIST";
export const SUCCESS_GET_INSTITUTES_LIST = "SUCCESS_GET_INSTITUTES_LIST";
export const SELECT_INSTITUTE = "SELECT_INSTITUTE";
export const SUCCESS_SET_INSTITUTE = "SUCCESS_SET_INSTITUTE";
export const GET_ARTICLE_GROUPS = "GET_ARTICLE_GROUPS";
export const SUCCESS_GET_ARTICLE_GROUPS = "SUCCESS_GET_ARTICLE_GROUPS";
export const GET_ARTICLES = "GET_ARTICLES";
export const SUCCESS_GET_ARTICLES = "SUCCESS_GET_ARTICLES";

//Cart
export const CREATE_CART = "CREATE_CART";
export const SUCCESS_CREATED_CART = "SUCCESS_CREATED_CART";
export const GET_CART = "GET_CART";
export const SET_CART = "SET_CART";
export const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART";
export const DELETE_ITEM_FROM_CART = "DELETE_ITEM_FROM_CART";
export const CONFIRM_CART = "CONFIRM_CART";
export const REMOVE_CART = "REMOVE_CART";
export const SET_CARTS_INSTITUTE = "SET_CARTS_INSTITUTE";

//Article description
export const SET_DETAILS = "SET_DETAILS";
export const REMOVE_DETAILS = "REMOVE_DETAILS";

//Institute description
export const SET_INSTITUTE_DETAILS = "SET_INSTITUTE_DETAILS";
export const REMOVE_INSTITUTE_DETAILS = "REMOVE_INSTITUTE_DETAILS";

//Related articles
export const SET_RELATED_ARTICLES = "SET_RELATED_ARTICLES";
export const REMOVE_RELATED_ARTICLES = "REMOVE_RELATED_ARTICLES";
