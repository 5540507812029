import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";

import * as path from "./../common/routeTable";

export const LoginRoute = ({ isAuthorize, ...rest }) => {
  if (isAuthorize) {
    return <Redirect to={path.DashboardPage} />;
  }
  return <Route {...rest} />;
};

LoginRoute.propTypes = {
  isAuthorize: PropTypes.bool.isRequired,
};
