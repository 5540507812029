import * as url from "./urls";
import ApiService from "./ApiService";

class AuthorizationService {
  async login(data) {
    const response = await ApiService.post(url.LoginUrl, data);
    return response.data;
  }

  async refreshToken(data) {
    const response = await ApiService.patch(url.LoginUrl, data);
    return response.data;
  }
}

export default AuthorizationService = new AuthorizationService();
