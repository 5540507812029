import React, { useEffect } from "react";
import { withLocalize, Translate } from "react-localize-redux";
import { CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getInstituteDetails } from "../../../state/actions/instituteDescriptionAction";
import { LocationOn, AccessTime, Message } from "@material-ui/icons";

const InstituteDetails = ({ instituteId, activeLanguage }) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.instituteDescription);

  useEffect(() => {
    dispatch(
      getInstituteDetails({
        instituteId: instituteId,
        lang: activeLanguage.code
      })
    );
  }, [instituteId]);

  return (
    <div className="articleDetails__instituteDetails">
      {state.loading ? (
        <div className="circularProgress circularProgress--medium">
          <CircularProgress size={50} height={50} className="circularProgress--dark" />
        </div>
      ) : (
        <>
          <h2 className="articleDetails__subtitle">{state.details.name}</h2>
          <div className="articleDetails__instituteDetailsWrapper">
            <div className="articleDetails__pictureWrapper">
              <img
                className="articleDetails__institutePicture"
                src={`data:image/png;base64,${state.details.picture}`}
              />
            </div>
            <div className="articleDetails__infoWrapper">
              <div className="articleDetails__addressWrapper">
                <div className="articleDetails__iconWrapper">
                  <LocationOn />
                </div>
                <div className="articleDetails__textWrapper">
                  <p className="articleDetails__address">
                    <Translate id="article_details.address" />
                  </p>
                  <p className="articleDetails__address">
                    {state.details.address.street} {state.details.address.number}
                  </p>
                  <p className="articleDetails__address">
                    {state.details.address.postalCode} {state.details.address.city}
                  </p>
                  <p className="articleDetails__address--map">
                    <a
                      className="articleDetails__address"
                      href={state.details.address.mapUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Translate id="article_details.google_maps" />
                    </a>
                  </p>
                </div>
              </div>
              <div className="articleDetails__hoursWrapper">
                <div className="articleDetails__iconWrapper">
                  <AccessTime />
                </div>
                <div className="articleDetails__textWrapper">
                  <p className="articleDetails__hour articleDetails__hourHeader">
                    <Translate id="article_details.hours" />
                  </p>
                  {state.details.openingHours.map(el => {
                    return (
                      <p className="articleDetails__hour" key={el.from}>
                        {el.dayStart === el.dayEnd ? (
                          <>
                            <Translate id={`article_details.days.d${el.dayStart}`} />: {el.from.slice(0, -3)} -{" "}
                            {el.to.slice(0, -3)}
                          </>
                        ) : (
                          <>
                            <Translate id={`article_details.days.d${el.dayStart}`} /> -{" "}
                            <Translate id={`article_details.days.d${el.dayEnd}`} />: {el.from.slice(0, -3)} -{" "}
                            {el.to.slice(0, -3)}
                          </>
                        )}
                      </p>
                    );
                  })}
                </div>
              </div>
              <div className="articleDetails__contactWrapper">
                <div className="articleDetails__iconWrapper">
                  <Message />
                </div>
                <div className="articleDetails__textWrapper">
                  <p className="articleDetails__contact articleDetails__contactHeader">
                    <Translate id="article_details.contact" />
                  </p>
                  <p>
                    <a className="articleDetails__contact" href={`tel:${state.details.contact.firstPhone}`}>
                      {state.details.contact.firstPhone}
                    </a>
                  </p>
                  <p>
                    <a className="articleDetails__contact" href={`tel:${state.details.contact.secondPhone}`}>
                      {state.details.contact.secondPhone}
                    </a>
                  </p>
                  <p>
                    <a className="articleDetails__contact" href={`mailto:${state.details.contact.email}`}>
                      {state.details.contact.email}
                    </a>
                  </p>
                  <p>
                    <a
                      className="articleDetails__contact"
                      href={state.details.contact.websiteUrl}
                      target="_blank"
                      rel="noopener norefferer"
                    >
                      {state.details.contact.websiteUrl}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withLocalize(InstituteDetails);
