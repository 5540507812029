import React, { useEffect, useState } from "react";
import { withLocalize, Translate } from "react-localize-redux";
import Layout from "../Layout";
import InstituteDetails from "./InstituteDetails";
import ArticleDescription from "./ArticleDescription";
import RelatedProducts from "./RelatedProducts";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { SelectDate } from "../../common/routeTable";

const ArticleDetails = () => {
  let params = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const [instituteId, setInstituteId] = useState(params.get("instituteId"));
  const [articleId, setArticleId] = useState(params.get("productId"));

  useEffect(() => {
    setInstituteId(params.get("instituteId"));
    setArticleId(params.get("productId"));
  }, [params]);

  const handleButtonClick = () => {
    dispatch(push(SelectDate + `?instituteId=${instituteId}&productId=${articleId}`));
  };

  return (
    <Layout>
      <div className="articleDetails">
        <h1 className="articleDetails__title">
          <Translate id="article_details.selected_uppercase" />
        </h1>
        <InstituteDetails instituteId={instituteId} />
        <ArticleDescription instituteId={instituteId} articleId={articleId} />
        <h2 className="articleDetails__subtitle">
          <Translate id="article_details.disclaimer" />
          </h2>
        <div className="articleDetails__addButtonWrapper">
          <button className="buttonGroup__button articleDetails__addButton" onClick={() => handleButtonClick()}>
            <Translate id="article_details.add_button" />
          </button>
        </div>
        <RelatedProducts instituteId={instituteId} articleId={articleId} />
      </div>
    </Layout>
  );
};

export default withLocalize(ArticleDetails);
