import React from "react";
import { Translate } from "react-localize-redux";

const required = value =>
  value === undefined || value === null || value.trim() === "" ? (
    <Translate id="validate.FieldIsRequired" />
  ) : (
    undefined
  );

const requiredSelect = value =>
  value === undefined || value === null ? <Translate id="validate.FieldIsRequired" /> : undefined;

const requiredTime = value => (!timeRegex().test(value) ? <Translate id="validate.IncorrectTime" /> : undefined);

const emailFormat = value => {
  if (value === undefined || value === null || value.length === 0) {
    return undefined;
  }

  return !emailRegex().test(value) ? <Translate id="validate.IncorrectEmail" /> : undefined;
};

const maxLength = max => value => {
  if (value === undefined || value === null || value.length === 0) {
    return undefined;
  }

  if (value.length > max) {
    return <Translate>{({ translate }) => <>{translate("validate.MaxLength", null) + max}</>}</Translate>;
  }

  return undefined;
};

const postCodeFormat = value => {
  if (value === undefined || value === null || value.length === 0) {
    return undefined;
  }

  return !postCodeRegex().test(value) ? <Translate id="validate.IncorrectPostCode" /> : undefined;
};

const requiredAgreement = value =>
  value === undefined || value === null || value === false ? <Translate id="validate.AgreementRequired" /> : undefined;

const normalizeTime = value => {
  if (value.indexOf("_") !== -1) {
    return value;
  }

  if (!timeRegex().test(value)) {
    return "23:59";
  }

  return value;
};

const normalizeNotRequiredField = value => (value === "" ? null : value);

function timeRegex() {
  return RegExp(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/);
}

function emailRegex() {
  return RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
}

function postCodeRegex() {
  return RegExp(/\d{2}-\d{3}$/);
}

export {
  required,
  requiredTime,
  emailFormat,
  requiredSelect,
  postCodeFormat,
  maxLength,
  requiredAgreement,
  normalizeNotRequiredField,
  normalizeTime
};
