import * as actionsType from "../actions/actionsType";
import { updateObject } from "../utils/updateObject";

const initialState = {
  message: undefined,
  isError: false,
  showModal: false
};

export default (state = initialState, { type, message }) => {
  switch (type) {
    case actionsType.SHOW_ERROR:
      return showError(state, message);
    case actionsType.SHOW_MESSAGE_MODAL:
      return showMessageModal(state, message);
    case actionsType.CLOSE_MESSAGE_MODAL:
      return initialState;
    default:
      return state;
  }
};

const showError = (state, message) => {
  return updateObject(state, { message: message, isError: true, showModal: true });
};

const showMessageModal = (state, message) => {
  return updateObject(state, { message: message, showModal: true });
};
