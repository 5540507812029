import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import classNames from "classnames";

export const CustomSelect = ({ input, children, placeholder, isReadOnly = false, meta: { touched, error } }) => {
  const classes = useStyles();
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <FormControl
      variant="outlined"
      className={classNames(classes.formControl, classes.blockSelect)}
      error={touched && error !== undefined}
    >
      <InputLabel ref={inputLabel} name={input.name}>
        {placeholder}
      </InputLabel>
      <Select
        labelWidth={labelWidth}
        readOnly={isReadOnly}
        value={input.value}
        onChange={input.onChange}
        style={{ height: "56px" }}
      >
        {children}
      </Select>
      {touched && error !== undefined ? <FormHelperText>{error}</FormHelperText> : <></>}
    </FormControl>
  );
};

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 50
  },
  errorMessage: {
    minHeight: "20px",
    margin: "-10px 0 0 0",
    "@media (max-width: 767px)": {
      minHeight: "0",
      margin: "-10px 0 0 0"
    }
  },

  blockSelect: {
    width: "100%",
    margin: "0",
    minHeight: "80px",
    height: "100%",
    "@media (max-width: 767px)": {
      margin: "4px 0"
    }
  }
}));
