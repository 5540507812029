import * as actionsType from "./actionsType";
import ReservationService from "../services/ReservationService";
import { push, replace } from "connected-react-router";
import * as path from "./../../common/routeTable";

export const getInstitutesList = data => async dispatch => {
  const response = await ReservationService.getInstitutesList();
  dispatch(setInstitutesList(response));
};

export const getFullRedirection = data => async dispatch => {
  const response = await ReservationService.getInstitutesRealId(data);
  dispatch(replace(path.ArticleDetails + `?instituteId=${response.id}&productId=${data.articleId}`));
};

export const getLocationRedirection = data => async dispatch => {
  const response = await ReservationService.getInstitutesRealId(data);
  dispatch(replace(path.ArticleGroups + `?instituteId=${response.id}`));
};

export const setInstitute = data => async dispatch => {
  dispatch(selectInstitute(data.instituteId));
  if (data.articleId) {
    dispatch(push(path.ArticleDetails + `?instituteId=${data.instituteId}&productId=${data.articleId}`));
  } else {
    dispatch(push(path.ArticleGroups + `?instituteId=${data.instituteId}`));
  }
};

export const getArticleGroups = data => async dispatch => {
  const response = await ReservationService.getArticleGroups(data);
  dispatch(setArticleGroups(response));
};

const setInstitutesList = payload => ({
  type: actionsType.SUCCESS_GET_INSTITUTES_LIST,
  payload: payload
});

const selectInstitute = payload => ({
  type: actionsType.SELECT_INSTITUTE,
  payload: payload
});

const setArticleGroups = payload => ({
  type: actionsType.SUCCESS_GET_ARTICLE_GROUPS,
  payload: payload
});
