import { combineReducers } from "redux";
import messageModalReducer from "./messageModalReducer";
import authorizeReducer from "./authorizeReducer";
import institutesPanelReducer from "./institutesPanelReducer";
import { reducer as formReducer } from "redux-form";
import { connectRouter } from "connected-react-router";
import { localizeReducer } from "react-localize-redux";
import reservationReducer from "./reservationReducer";
import cartReducer from "./cartReducer";
import articleDescriptionReducer from "./articleDescriptionReducer";
import instituteDescriptionReducer from "./instituteDescriptionReducer";
import relatedArticlesReducer from "./relatedArticlesReducer";

export const getReducers = history => {
  return combineReducers({
    router: connectRouter(history),
    form: formReducer,
    messageModal: messageModalReducer,
    authorize: authorizeReducer,
    localize: localizeReducer,
    institutesPanel: institutesPanelReducer,
    reservations: reservationReducer,
    cart: cartReducer,
    articleDescription: articleDescriptionReducer,
    instituteDescription: instituteDescriptionReducer,
    relatedArticles: relatedArticlesReducer
  });
};
