import React from "react";
import { Translate } from "react-localize-redux";
import { FormHelperText } from "@material-ui/core";

export const SummaryInput = ({ input, placeholder, type, meta: { touched, error } }) => {
  return (
    <div className="summary__inputWrapper">
      <Translate>
        {({ translate }) => (
          <input
            className="summary__summaryInput"
            {...input}
            value={input.value.toUpperCase()}
            placeholder={translate(placeholder)}
            type={type}
            autoComplete={input.value}
          />
        )}
      </Translate>
      {touched && error !== undefined ? <FormHelperText error>{error}</FormHelperText> : <></>}
    </div>
  );
};
