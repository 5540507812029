import React from "react";
import { Translate } from "react-localize-redux";
import { FormHelperText } from "@material-ui/core";

export const SummaryCheckBox = ({ input, type, id, label, meta: { touched, error } }) => {
  return (
    <div className="summary__checkBox">
      <Translate>
        {({ translate }) => (
          <label className="summary__checkBoxWrapper">
            <input
              type="checkbox"
              className="summary__checkBoxInput"
              checked={input.value}
              onChange={e => input.onChange(e.target.checked)}
            />
            <span className="summary__checkBoxCheckmark"></span>
            {translate(label)}
            {touched && error !== undefined ? <FormHelperText error>{error}</FormHelperText> : <></>}
          </label>
        )}
      </Translate>
    </div>
  );
};
