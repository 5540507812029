import ApiService from "./ApiService";
import * as url from "./urls";

class InstitutesPanelService {
  async getInstitutes(token) {
    return await ApiService.get(url.InstitutesPanelUrl, {
      headers: { Authorization: `Bearer ${token}` }
    });
  }

  async getInstitute(token, id) {
    return await ApiService.get(`${url.InstitutesPanelUrl}/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(response => {
      let institute = { ...response.data };
      institute.group = institute.group.id;
      return institute;
    });
  }

  async createInstitute(token, data) {
    return await ApiService.post(url.InstitutesPanelUrl, data, {
      headers: { Authorization: `Bearer ${token}` }
    });
  }

  async getGroupDictionary(token) {
    return await ApiService.get(url.GroupDictionaryUrl, {
      headers: { Authorization: `Bearer ${token}` }
    });
  }

  async editIntstitute(token, data, id) {
    return await ApiService.put(`${url.InstitutesPanelUrl}/${id}`, data, {
      headers: { Authorization: `Bearer ${token}` }
    });
  }
}

export default InstitutesPanelService = new InstitutesPanelService();
