import React, { useEffect } from "react";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { withLocalize } from "react-localize-redux";

const Layout = ({ children, setActiveLanguage }) => {
  useEffect(() => {
    setActiveLanguage("pl");
  }, []);

  return (
    <main className="dashboard">
      <Header />
      <div className="dashboard__inner">{children}</div>
      <Footer />
    </main>
  );
};

export default withLocalize(Layout);
