import * as actionsType from "./../actions/actionsType";
import { updateObject } from "./../utils/updateObject";

const initialState = {
  accessToken: undefined,
  refreshToken: undefined,
  isAuthorize: false,
  isRefreshing: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionsType.SUCCES_LOGIN:
      return succesLogin(state, payload);
    case actionsType.LOGOUT:
      return initialState;
    case actionsType.REFRESH_TOKEN:
      return refreshToken(state, payload);
    default:
      return state;
  }
};

const succesLogin = (state, payload) => {
  return updateObject(state, {
    accessToken: payload.accessToken,
    refreshToken: payload.refreshToken,
    isAuthorize: true,
    isRefreshing: false
  });
};

const refreshToken = (state, payload) => {
  return updateObject(state, {
    isRefreshing: true,
    isAuthorize: true,
    accessToken: payload.accessToken,
    refreshToken: payload.refreshToken
  });
};
