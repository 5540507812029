import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withLocalize, Translate } from "react-localize-redux";
import Layout from "../Layout";
import DatePicker from "./DatePicker";
import { useDispatch, useSelector } from "react-redux";
import CartSummary from "../Summary/CartSummary";

const SelectDate = () => {
  const state = useSelector((state) => state.cart);
  let params = new URLSearchParams(window.location.search);
  const [instituteId, setInstituteId] = useState(params.get("instituteId"));
  const [articleId, setArticleId] = useState(params.get("productId"));

  useEffect(() => {
    setInstituteId(params.get("instituteId"));
    setArticleId(params.get("productId"));
  }, [params]);

  return (
    <Layout>
      <div className="selectDate">
        {state.cart.items.length === 0 ? null : (
          <>
            <h1 className="selectDate__title">
              <Translate id="select_date.reserv_title" />
            </h1>
            <CartSummary />
          </>
        )}
        <h1 className="selectDate__title">
          <Translate id="select_date.title" />
        </h1>
        <DatePicker CanIterateBack={false} instituteId={instituteId} articleId={articleId} />
      </div>
    </Layout>
  );
};

export default withLocalize(SelectDate);
