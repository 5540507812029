import * as url from "./urls";
import ApiService from "./ApiService";

class ReservationService {
  async getInstitutesList() {
    const response = await ApiService.get(url.InstitutesList);
    if (response.status === 200) {
      return response.data;
    }
  }

  async getInstitutesRealId(data) {
    const response = await ApiService.get(url.InstitutesRealId(data.id, data.lang));
    if (response.status === 200) {
      return response.data;
    }
  }

  async getArticleGroups(data) {
    const response = await ApiService.get(url.ArticleGroups(data.instituteId, data.lang));
    if (response.status === 200) {
      return response.data;
    }
  }

  async getGroupArticles(data) {
    const response = await ApiService.get(url.GroupArticles(data.instituteId, data.groupId, data.lang));
    if (response.status === 200) {
      return response.data;
    }
  }

  async getArticleDetails(data) {
    const response = await ApiService.get(url.ArticleDetails(data.instituteId, data.articleId, data.lang));
    if (response.status === 200) {
      return response.data;
    }
  }

  async getRelatedArticles(data) {
    const response = await ApiService.get(url.RelatedArticles(data.instituteId, data.articleId, data.lang));
    if (response.status === 200) {
      return response.data;
    }
  }

  async getInstituteDetails(data) {
    const response = await ApiService.get(url.InstituteDetailsPreview(data.instituteId, data.lang));
    if (response.status === 200) {
      return response.data;
    }
  }

  async getArticlesAvailableHours(data) {
    const response = await ApiService.get(
      url.GetAvailableHours(data.instituteId, data.articleId, data.lang, data.date)
    );
    if (response.status === 200) {
      return response.data;
    }
  }

  async getEmployeeList(data) {
    const response = await ApiService.get(
      url.EmployeeList(data.instituteId, data.articleId, data.lang, data.date, data.hour)
    );
    if (response.status === 200) {
      return response.data;
    }
  }
}

export default ReservationService = new ReservationService();
