import React from "react";
import { withLocalize, Translate } from "react-localize-redux";
import { AppBar, Toolbar, Typography, Button, Badge, Tooltip, Menu, MenuItem, IconButton } from "@material-ui/core";
import {
  Translate as TranslateIcon,
  ExpandMore as ExpandMoreIcon,
  ShoppingCart as ShoppingCartIcon
} from "@material-ui/icons";
import { ReactComponent as PlLogo } from "../../../images/logo-pl.svg";
import { ReactComponent as EnLogo } from "../../../images/logo-en.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootPage, Summary } from "../../../common/routeTable";
import { push } from "connected-react-router";

const Header = ({ languages, activeLanguage, setActiveLanguage }) => {
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const [languageMenu, setLanguageMenu] = React.useState(null);

  const handleLanguageIconClick = event => {
    setLanguageMenu(event.currentTarget);
  };

  const handleLanguageMenuClose = event => {
    setLanguageMenu(null);
  };

  const setNewLanguage = code => {
    setActiveLanguage(code);
    setLanguageMenu(null);
  };

  const handleCartButtonClick = () => {
    dispatch(push(Summary));
  };

  return (
    <AppBar className="mainHeader" position="static">
      <Toolbar>
        <div className="mainHeader__buttonWrapper mainHeader__buttonWrapper--left">
          <div className="mainHeader__hidden--tablet-up">
            <Tooltip title={<Translate id="change_language" />} enterDelay={300}>
              <Button
                className="mainHeader__button"
                color="inherit"
                aria-owns={languageMenu ? "language-menu" : undefined}
                aria-haspopup="true"
                aria-label={"changeLanguage"}
                onClick={handleLanguageIconClick}
                data-ga-event-category="AppBar"
                data-ga-event-action="language"
              >
                <TranslateIcon />
                <span className="mainHeader__span">{activeLanguage.name}</span>
                <ExpandMoreIcon fontSize="small" />
              </Button>
            </Tooltip>
          </div>
        </div>
        {activeLanguage.code === "pl" ? (
          <PlLogo className="mainHeader__svgLogo" onClick={() => dispatch(push(RootPage))} />
        ) : (
          <EnLogo className="mainHeader__svgLogo" onClick={() => dispatch(push(RootPage))} />
        )}
        <div className="mainHeader__buttonWrapper mainHeader__buttonWrapper--right">
          <div className="mainHeader__hidden--tablet-down">
            <Tooltip title={<Translate id="change_language" />} enterDelay={300}>
              <Button
                className="mainHeader__button"
                color="inherit"
                aria-owns={languageMenu ? "language-menu" : undefined}
                aria-haspopup="true"
                aria-label={"changeLanguage"}
                onClick={handleLanguageIconClick}
                data-ga-event-category="AppBar"
                data-ga-event-action="language"
              >
                <TranslateIcon />
                <span className="mainHeader__span">{activeLanguage.name}</span>
                <ExpandMoreIcon fontSize="small" />
              </Button>
            </Tooltip>
            <Tooltip title={<Translate id="cart" />} enterDelay={300}>
              <IconButton
                className="mainHeader__button"
                color="inherit"
                aria-label="add to shopping cart"
                onClick={() => handleCartButtonClick()}
              >
                {cart.cart && cart.cart.items.length > 0 ? (
                  <Badge badgeContent={cart.cart.items.length} color="secondary">
                    <ShoppingCartIcon />
                  </Badge>
                ) : (
                  <ShoppingCartIcon />
                )}
              </IconButton>
            </Tooltip>
          </div>
          <div className="mainHeader__hidden--tablet-up">
            <Tooltip title={<Translate id="cart" />} enterDelay={300}>
              <IconButton
                className="mainHeader__button"
                color="inherit"
                aria-label="add to shopping cart"
                onClick={() => handleCartButtonClick()}
              >
                {cart.cart && cart.cart.items.length > 0 ? (
                  <Badge badgeContent={cart.cart.items.length} color="secondary">
                    <ShoppingCartIcon />
                  </Badge>
                ) : (
                  <ShoppingCartIcon />
                )}
              </IconButton>
            </Tooltip>
          </div>
          <Menu
            id="language-menu"
            anchorEl={languageMenu}
            open={Boolean(languageMenu)}
            onClose={handleLanguageMenuClose}
          >
            {languages.map(language => (
              <MenuItem
                style={{ width: "100%" }}
                component="button"
                data-no-link="true"
                key={language.code}
                selected={activeLanguage.code === language.code}
                onClick={() => setNewLanguage(language.code)}
                lang={language.code}
                hrefLang={language.code}
              >
                {language.name}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default withLocalize(Header);
