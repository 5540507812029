import React from "react";
import { Translate } from "react-localize-redux";
import { SubmissionError } from "redux-form";
import { showError } from "./../actions/messageModalAction";

const externalFields = ["openingHours", "instituteId"];

const submitForm = (method, dispatch) => {
  return method.catch(e => {
    if (e.errorType) {
      if (e.errorFields === undefined) {
        throw new SubmissionError(e.errorFields);
      }

      let isExternalField = false;
      let externallMessage = "";
      let errorFields = {};
      if (!Array.isArray(e.errorFields)) {
        throw e;
      }

      e.errorFields.forEach(value => {
        if (value.field === undefined) {
          throw e;
        }

        let fields = value.field.split(".");
        if (fields.length > 1) {
          let nameObject = firstLetterToLower(fields[0]);
          let nameField = firstLetterToLower(fields[1]);

          if (errorFields[nameObject] === undefined) {
            errorFields[nameObject] = {};
          }
          errorFields[nameObject][nameField] = <Translate id={`validate.${value.message}`} />;
          return;
        }
        let nameField = firstLetterToLower(value.field);

        if (externalFields.includes(nameField)) {
          isExternalField = true;
          externallMessage = value.message;
          return;
        }

        errorFields[nameField] = <Translate id={`validate.${value.message}`} />;
      });

      if (isExternalField) {
        dispatch(showError(<Translate id={`validate.${externallMessage}`} />));
        return;
      }

      throw new SubmissionError(errorFields);
    }
    throw e;
  });
};

function firstLetterToLower(value) {
  return value.charAt(0).toLowerCase() + value.slice(1);
}

export default submitForm;
