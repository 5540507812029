import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import * as path from "./../common/routeTable";

export const ProtectedRoute = ({ isAuthorize, ...rest }) => {
  if (!isAuthorize) {
    return <Redirect to={path.LoginPage} />;
  }

  return <Route {...rest} />;
};

ProtectedRoute.propTypes = {
  isAuthorize: PropTypes.bool.isRequired
};
