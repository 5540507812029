import React from "react";
import { Translate, withLocalize } from "react-localize-redux";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { ArticleDetails } from "../../../../../common/routeTable";

const Article = ({ articleName, articlePrice, articleDuration, articleId, instituteId, groupId, anyPrice }) => {
  const dispatch = useDispatch();
  const handleButtonClick = () => {
    dispatch(push(ArticleDetails + `?instituteId=${instituteId}&productId=${articleId}`));
  };
  return (
    <div className="articleGroups__article">
      <p className="articleGroups__articleName">{articleName}</p>
      <p className="articleGroups__articleName articleGroups__articleName--articlePrice">
        {articleDuration} min/ {anyPrice === 1 ? <Translate id="article_groups.any_price" />  : null}
        {articlePrice}
        {" "}<Translate id="article_details.currency" />
        {anyPrice === 1 ? ' *' : null}
      </p>
      <button className="articleGroups__articleButton buttonGroup__button" onClick={() => handleButtonClick()}>
        <Translate id="article_groups.add_button" />
      </button>
    </div>
  );
};

export default withLocalize(Article);
