import React from "react";
import { Card, CardHeader, CardContent, Grid, MenuItem, Box } from "@material-ui/core";
import { Field, reduxForm } from "redux-form";
import * as validate from "./../../../../common/validate";
import { CustomInput } from "../../../UI/CustomInput";
import { CustomButton } from "./../../../UI/CustomButton";
import { CustomSelect } from "../../../UI/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import { addOpeningHours, removeOpeningHours } from "./../../../../state/actions/institutesPanelAction";
import submitForm from "./../../../../state/utils/submitForm";

const CardOpeningHours = ({ submitting, handleSubmit }) => {
  const dispatch = useDispatch();
  const state = useSelector(
    state => state?.institutesPanel?.openingHours?.sort((day, nextDay) => (day.day > nextDay.day ? 1 : -1)) || []
  );

  return (
    <Card>
      <CardHeader component="h2" title="Godziny otwarcia" />
      <CardContent>
        <form onSubmit={handleSubmit(values => submitForm(dispatch(addOpeningHours(values)), dispatch))}>
          <Grid container className="dashboard__blockContent" spacing={1} alignItems="flex-start">
            <Grid item lg={5} xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Field
                    name="day"
                    component={CustomSelect}
                    placeholder="Dzień tygodnia"
                    validate={[validate.requiredSelect]}
                  >
                    <MenuItem value={1}>Poniedziałek</MenuItem>
                    <MenuItem value={2}>Wtorek</MenuItem>
                    <MenuItem value={3}>Środa</MenuItem>
                    <MenuItem value={4}>Czwartek</MenuItem>
                    <MenuItem value={5}>Piątek</MenuItem>
                    <MenuItem value={6}>Sobota</MenuItem>
                    <MenuItem value={7}>Niedziela</MenuItem>
                  </Field>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Field
                    name="from"
                    component={CustomInput}
                    placeholder="Od"
                    validate={[validate.requiredTime]}
                    normalize={validate.normalizeTime}
                    mask={customMask}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Field
                    name="to"
                    component={CustomInput}
                    placeholder="Do"
                    validate={[validate.requiredTime]}
                    normalize={validate.normalizeTime}
                    mask={customMask}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={1} xs={12}>
              <Box className="dashboard__blockContentCenter">
                <CustomButton submitting={submitting} type="submit">
                  Dodaj
                </CustomButton>
              </Box>
            </Grid>
            <Grid item lg={6} xs={12} className="dashboard__blockContentRight">
              <h2 className="dashboard__blockHeader text--center">Dni tygodnia</h2>

              {state?.length === 0 ? (
                <p className="text--center">Brak zadeklarowanych dni</p>
              ) : (
                state.map((day, key) => (
                  <Grid container key={key} className="dashboard__blockContentItem">
                    <Grid item xs={2} md={4} className="dashboard__blockContentItemRow">
                      <span className="dashboard__dayofWeek">{dayOfWeek[day.day - 1]}</span>
                      <span className="dashboard__dayofWeekShort">{dayOfWeekShort[day.day - 1]}</span>
                    </Grid>
                    <Grid item xs={8} md={6} className="text--center dashboard__blockContentItemRow">
                      {day.from.length === 8 ? day.from.slice(0, -3) : day.from} -{" "}
                      {day.to.length === 8 ? day.to.slice(0, -3) : day.to}
                    </Grid>
                    <Grid item xs={2} className="text--center">
                      <CustomButton onClick={() => dispatch(removeOpeningHours(day.day))}>Kasuj</CustomButton>
                    </Grid>
                  </Grid>
                ))
              )}
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

const customMask = {
  mask: "99:99"
};

const dayOfWeek = ["Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota", "Niedziela"];
const dayOfWeekShort = ["Pon", "Wt", "Śr", "Czw", "Pt", "Sob", "Ndz"];

export default reduxForm({ form: "cardOpeningHoursForm" })(CardOpeningHours);
