import React, { useState, useEffect } from "react";
import { withLocalize, Translate } from "react-localize-redux";
import PropTypes from "prop-types";
import ButtonGroupModal from "../ButtonGroupModal";
import { setInstitute } from "../../../state/actions/reservationAction";
import { useDispatch, useSelector } from "react-redux";

const ButtonGroup = ({ cities, idPrefix, articleId }) => {
  const dispatch = useDispatch();

  const handleButtonClick = (institutesList) => {
      dispatch(setInstitute({ instituteId: institutesList[0].id, articleId: articleId }));
  };
  console.log(cities)
  return (
    <div className="buttonGroup" key={idPrefix}>
      {cities === undefined ? (
        <div className="buttonGroup__descriptionEmpty">
          <Translate id="reservation.descriptionEmpty" />
        </div>
      ) : (
        cities.map(city => {
          if (city.nested === false) {
            return (
            <button
              className="buttonGroup__button buttonGroup__button--start"
              key={idPrefix + "-" + city.city}
              onClick={() => handleButtonClick(city.institutes)}
            >
              <span className="buttonGroup__button--span">
                { city.city }
              </span>
            </button>
          );
        } else {
          return (
          city.institutes.map(nestedCity => {
            console.log(nestedCity)
            return (
              <button
                className="buttonGroup__button buttonGroup__button--start"
                key={idPrefix + "-" + city.city + "-" + nestedCity.name}
                onClick={() => handleButtonClick([nestedCity])}
              >
                <span className="buttonGroup__button--span">
                  { nestedCity.name }
                </span>
              </button>
              );
          })
          )
        }
      })
      )}
    </div>
  );
};

ButtonGroup.propTypes = {
  idPrefix: PropTypes.any,
  cities: PropTypes.arrayOf(PropTypes.object)
};

export default withLocalize(ButtonGroup);
