import React, { useEffect } from "react";
import { Card, CardHeader, CardContent, MenuItem } from "@material-ui/core";
import { Field } from "redux-form";
import * as validate from "./../../../../common/validate";
import { CustomInput } from "../../../UI/CustomInput";
import { AdressFields } from "../AdressFields/index";
import { CustomSwitch } from "../../../UI/CustomSwitch";
import { useSelector, useDispatch } from "react-redux";
import { getGroupDictionary } from "./../../../../state/actions/institutesPanelAction";
import { CustomSelect } from "../../../UI/CustomSelect";
import { CustomFileUpload } from "../../../UI/CustomFileUpload";

const maxLengthName = validate.maxLength(100);
const maxLengthApiUrl = validate.maxLength(114);

export const CardLocalData = () => {
  const dispatch = useDispatch();
  const dictionary = useSelector(state => state.institutesPanel.groupDictionary);

  useEffect(() => {
    dispatch(getGroupDictionary());
  }, []);

  return (
    <Card>
      <CardHeader component="h2" title="Dane lokalu" />
      <CardContent>
        <Field
          name="name"
          component={CustomInput}
          placeholder="Nazwa lokalu"
          validate={[validate.required, maxLengthName]}
        />
        <AdressFields />
        <Field name="group" component={CustomSelect} placeholder="Grupa" validate={[validate.requiredSelect]}>
          {dictionary === undefined ? (
            <></>
          ) : (
            dictionary.map((item, key) => (
              <MenuItem key={key} value={item.id}>
                {item.description}
              </MenuItem>
            ))
          )}
        </Field>
        <Field name="isActive" component={CustomSwitch} placeholder="Aktywny" />
        <Field name="useDefaultStaffMember" component={CustomSwitch} placeholder="Domyślny wybór personelu" />
        <Field
          name="apiUrl"
          component={CustomInput}
          placeholder="Api adres"
          validate={[validate.required, maxLengthApiUrl]}
        />
        <Field
          name="picture"
          component={CustomFileUpload}
          placeholder="Przesuń plik na obszar lub kliknij"
          validate={[validate.required]}
        />
      </CardContent>
    </Card>
  );
};
