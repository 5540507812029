import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";
import { withLocalize, Translate } from "react-localize-redux";
import { push, replace, goBack } from "connected-react-router";
import { Summary, RootPage, ThankYou, ArticleGroups } from "../../common/routeTable";
import { Grid } from "@material-ui/core";
import { getCart } from "../../state/actions/cartAction";
import { getFullRedirection, getLocationRedirection } from "../../state/actions/reservationAction";

const Layout = ({ children, setActiveLanguage, activeLanguage }) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.cart);
  let showButton = window.location.pathname !== ThankYou;

  useEffect(() => {
    let queryParams = new URLSearchParams(window.location.search);
    let lang = queryParams.get("lang");
    if (lang) {
      setActiveLanguage(lang);
    }
    let locationId = queryParams.get("locationId");
    let articleId = queryParams.get("articleId");

    if (locationId && articleId) {
      dispatch(getFullRedirection({ id: locationId, articleId: articleId, lang: activeLanguage.code }));
    } else if (locationId) {
      dispatch(getLocationRedirection({ id: locationId, lang: activeLanguage.code }));
    } else if (articleId) {
      dispatch(replace(RootPage + `?productId=${articleId}`));
    }
    //if cart exists
    if (state.instituteId && state.cart.id)
      dispatch(
        getCart({
          instituteId: state.instituteId,
          cartId: state.cart.id,
          lang: activeLanguage.code
        })
      );


  }, []);

  const handleBackButtonClick = () => {
    if (window.location.pathname.includes(Summary) && state.instituteId){
      dispatch(push(ArticleGroups + `?instituteId=${state.instituteId}`))
    }
    else{
      dispatch(goBack());
    }
  };

  return (
    <main className="layout">
      <Header />
      <div className="layout__mainDiv">{children}</div>
      {showButton ? (
        <Grid container className="layout__backButtonWrapper">
          <button className="reservation__button layout__backButton" onClick={() => handleBackButtonClick()}>
            <Translate id="return_lowercase" />
          </button>
        </Grid>
      ) : (
        <></>
      )}
      <Footer />
    </main>
  );
};

export default withLocalize(Layout);
