import * as actionsTypes from "./../actions/actionsType";
import { updateObject } from "./../utils/updateObject";

const initialState = {
  institutes: undefined,
  informationInstitute: undefined,
  openingHours: [],
  groupDictionary: [],
  loading: true,
  isOpenMenu: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionsTypes.GET_INSTITUTES_PANEL:
      return getInstitutesPanel(state, payload);
    case actionsTypes.GET_INSTITUTE:
      return getInstitute(state, payload);
    case actionsTypes.ADD_OPENING_HOURS:
      return addOpeningHours(state, payload);
    case actionsTypes.REMOVE_OPENING_HOURS:
      return removeOpeningHours(state, payload);
    case actionsTypes.CLEAR_INSITUTE:
      return clearInstitute(state);
    case actionsTypes.GET_GROUP_DICTIONARY:
      return setGroupDictionary(state, payload);
    case actionsTypes.SET_LOADING_DATA:
      return setLoadingData(state);
    case actionsTypes.OPEN_MENU:
      return setMenu(state, true);
    case actionsTypes.ClOSE_MENU:
      return setMenu(state, false);
    default:
      return state;
  }
};

const getInstitutesPanel = (state, institutes) => {
  return updateObject(state, { institutes: institutes });
};

const getInstitute = (state, institute) => {
  return updateObject(state, { informationInstitute: institute, openingHours: institute.openingHours, loading: false });
};

const addOpeningHours = (state, payload) => {
  let updatedOpeningHours = state.openingHours.slice();
  removeDay(updatedOpeningHours, payload.openingHours.day);

  updatedOpeningHours.push(payload.openingHours);

  return updateObject(state, { openingHours: updatedOpeningHours });
};

const removeOpeningHours = (state, dayId) => {
  let updatedOpeningHours = state.openingHours.slice();

  removeDay(updatedOpeningHours, dayId);

  return updateObject(state, { openingHours: updatedOpeningHours });
};

const removeDay = (updatedOpeningHours, dayID) => {
  let indexRemoveItem = updatedOpeningHours.findIndex(element => element.day === dayID);

  if (indexRemoveItem !== -1) {
    updatedOpeningHours.splice(indexRemoveItem, 1);
  }
};

const clearInstitute = state => {
  return updateObject(state, { informationInstitute: undefined, openingHours: [] });
};

const setGroupDictionary = (state, groupDictionary) => {
  return updateObject(state, { groupDictionary: groupDictionary });
};

const setLoadingData = state => {
  return updateObject(state, { loading: true });
};

const setMenu = (state, isOpenMenu) => {
  return updateObject(state, { isOpenMenu });
};
