import React from "react";
import { withLocalize, Translate } from "react-localize-redux";
import { useMediaQuery, Dialog, DialogTitle, DialogActions, DialogContent, Button } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { setInstitute } from "../../../state/actions/reservationAction";

const ButtonGroupModal = ({ institutes, open, handleClose, articleId }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleButtonClick = id => {
    dispatch(setInstitute({ instituteId: id, articleId: articleId }));
  };

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} fullWidth={true}>
        <DialogTitle id="responsive-dialog-title">
          <Translate id="reservation.modal.title" />
        </DialogTitle>
        <DialogContent>
          <div className="buttonGroup">
            {institutes.map(el => {
              return (
                <button
                  key={el.id}
                  className="buttonGroup__button buttonGroup__button--start buttonGroup__button--modal"
                  onClick={() => handleButtonClick(el.id)}
                >
                  <span className="buttonGroup__button--span">{el.name}</span>
                </button>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>
            <Translate id="close_button" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ButtonGroupModal.propTypes = {
  open: PropTypes.bool.isRequired,
  institutes: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleClose: PropTypes.func.isRequired
};

export default withLocalize(ButtonGroupModal);
