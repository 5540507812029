import * as actionsType from "./actionsType";
import AuthorizeService from "../services/AuthorizeService";
import { push } from "connected-react-router";
import * as path from "./../../common/routeTable";

export const login = data => async dispatch => {
  const response = await AuthorizeService.login(data);

  dispatch(setLogin(response));
  dispatch(push(path.DashboardPage));
};

export const logout = () => async dispatch => {
  dispatch(setLogout());
  dispatch(push(path.LoginPage));
};

export const refreshToken = data => async dispatch => {
  const response = await AuthorizeService.refreshToken(data);

  await dispatch(setRefreshToken(response));
};

const setLogin = payload => ({
  type: actionsType.SUCCES_LOGIN,
  payload: payload
});

const setLogout = () => ({
  type: actionsType.LOGOUT
});

const setRefreshToken = payload => ({
  type: actionsType.REFRESH_TOKEN,
  payload: payload
});
