import React from "react";
import { withLocalize, Translate } from "react-localize-redux";
import moment from "moment";

const ArticleDetails = ({ loading, articleName, articlePrice, hour, articleDuration }) => {
  let endHour = moment(hour, "HH:mm")
    .add(articleDuration, "minutes")
    .format("HH:mm");
  return (
    <div className="datePicker__articleDetailsWrapper">
      {loading ? (
        <></>
      ) : (
        <>
          <div className="datePicker__articleNameWrapper">
            <h5 className="datePicker__articleName">{articleName}</h5>
          </div>
          <div className="datePicker__articleInfoWrapper">
            <h6 className="datePicker__articlePrice">
              {articlePrice}&nbsp;
              <Translate id="article_details.currency" />
            </h6>
            <p className="datePicker__articleDuration">
              {hour && " | "}
              {hour}
              {hour && " - "}
              {hour && endHour}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default withLocalize(ArticleDetails);
