import React, { useEffect } from "react";
import Layout from "../Layout";
import { Container } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getInstitutes } from "./../../../state/actions/institutesPanelAction";
import MaterialTable from "material-table";
import { push } from "connected-react-router";
import * as urls from "./../../../common/routeTable";

export const Dashboard = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.institutesPanel);

  useEffect(() => {
    dispatch(getInstitutes());
  }, []);

  return (
    <Layout>
      <Container>
        <MaterialTable
          fullWidth
          title="Instytuty"
          columns={[
            { title: "Id", field: "externalId" },
            { title: "Nazwa lokalu", field: "name" },
            { title: "Aktywny", field: "isActive", type: "boolean" },
            {
              title: "Adres",
              field: "address"
            }
          ]}
          data={state.institutes}
          actions={[
            {
              icon: "edit",
              tooltip: "Edytuj",
              onClick: (event, rowData) => {
                dispatch(push(`${urls.InstitutePage}/${rowData.id}`));
              }
            }
          ]}
          localization={{
            pagination: {
              labelDisplayedRows: "{from}-{to} z {count}",
              labelRowsSelect: "wierszy",
              labelRowsPerPage: "wierszy na stronie",
              firstAriaLabel: "Pierwsza strona",
              firstTooltip: "Pierwsza strona",
              previousAriaLabel: "Poprzednia strona",
              previousTooltip: "Poprzednia strona",
              nextAriaLabel: "Następna strona",
              nextTooltip: "Następna strona",
              lastAriaLabel: "Ostatnia strona",
              lastTooltip: "Ostatnia strona"
            },
            toolbar: {
              nRowsSelected: "{0} wiersz(e) wybrany",
              searchTooltip: "Szukaj",
              searchPlaceholder: "Szukaj"
            },
            header: {
              actions: ""
            },
            body: {
              emptyDataSourceMessage: "Brak rekordów",
              filterRow: {
                filterTooltip: "Filtr"
              }
            }
          }}
          options={{
            actionsColumnIndex: -1,
            pageSize: 10
          }}
        />
      </Container>
    </Layout>
  );
};
