import React from "react";
import { CustomInput } from "../../../UI/CustomInput";
import * as validate from "../../../../common/validate";
import { Field } from "redux-form";

const maxLengthCity = validate.maxLength(50);
const maxLengthStreet = validate.maxLength(80);
const maxLengthNumber = validate.maxLength(21);

export const AdressFields = props => {
  return (
    <>
      <Field
        name="address.city"
        component={CustomInput}
        placeholder="Miasto"
        validate={[validate.required, maxLengthCity]}
        autoComplete="no"
      />
      <Field
        name="address.postalCode"
        component={CustomInput}
        placeholder="Kod pocztowy"
        mask={{ mask: "99-999" }}
        validate={[validate.required, validate.postCodeFormat]}
        autoComplete="no"
      />
      <Field
        name="address.street"
        component={CustomInput}
        placeholder="Ulica"
        validate={[validate.required, maxLengthStreet]}
        autoComplete="no"
      />
      <Field
        name="address.number"
        component={CustomInput}
        placeholder="Numer lokalu"
        validate={[validate.required, maxLengthNumber]}
        autoComplete="no"
      />
    </>
  );
};
