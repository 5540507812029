import moment from "moment";

export const overlapChecker = (items, selectedHour, duration, selectedDate) => {
  let startHour = moment(selectedHour, "HH:mm");
  let endHour = moment(startHour).add(duration, "minutes");
  for (let item in items) {
    if (selectedDate === moment(items[item].date)) {
      let itemStartHour = moment(items[item].hour, "HH:mm");
      let itemEndHour = moment(itemStartHour).add(items[item].duration, "minutes");
      if (startHour >= itemStartHour && startHour < itemEndHour) {
        return true;
      }
      if (endHour > itemStartHour && endHour < itemEndHour) {
        return true;
      }
    }
  }
  return false;
};
