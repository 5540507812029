import React from "react";
import { FormControlLabel, Switch } from "@material-ui/core";

export const CustomSwitch = ({ input, ownStyle, placeholder }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={input.value ? true : false}
          onChange={input.onChange}
          value="checkedB"
          color="primary"
          name={input.name}
        />
      }
      label={placeholder}
      style={{
        width: "100%",
        minHeight: "56px",
        marginBottom: 24,
        ...ownStyle
      }}
    />
  );
};
