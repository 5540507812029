import * as actionsType from "./../actions/actionsType";
import { updateObject } from "./../utils/updateObject";

const initialState = {
  cart: {
    items: []
  },
  instituteId: undefined,
  loading: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionsType.SET_CART:
      return setCart(state, payload);
    case actionsType.GET_CART:
      return setLoading(state);
    case actionsType.SET_CARTS_INSTITUTE:
      return setCartsInstitute(state, payload);
    case actionsType.REMOVE_CART:
      return removeCart(state, payload);
    default:
      return state;
  }
};

const setCart = (state, payload) => {
  return updateObject(state, {
    cart: payload,
    loading: false
  });
};

const setLoading = state => {
  return updateObject(state, {
    loading: true
  });
};

const setCartsInstitute = (state, payload) => {
  return updateObject(state, {
    instituteId: payload
  });
};

const removeCart = (state, payload) => {
  return updateObject(state, {
    cart: {
      items: []
    },
    loading: false,
    instituteId: null
  });
};
