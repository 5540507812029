export const RootPage = "/";
export const ArticleGroups = "/article-groups";
export const LoginPage = "/login";
export const DashboardPage = "/dashboard";
export const InstitutePage = "/institute";
export const InstituteEditPage = "/institute/:id";
export const ThankYou = "/thank-you";
export const ArticleDetails = "/article-details";
export const SelectDate = "/select-date";
export const Summary = "/summary";
