import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withLocalize, Translate } from "react-localize-redux";
import Layout from "../Layout";
import CartSummary from "./CartSummary";
import ClientDataForm from "./ClientDataForm";
import { useSelector } from "react-redux";

const Summary = () => {
  const cart = useSelector(store => store.cart);
  let render = cart.cart.items ? (cart.cart.items.length > 0 ? true : false) : false;

  return (
    <Layout>
      <div className="summary">
        <CartSummary />
        {render ? <ClientDataForm /> : <> </>}
      </div>
    </Layout>
  );
};

export default withLocalize(Summary);
