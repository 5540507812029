import React from "react";
import { withLocalize, Translate } from "react-localize-redux";
import Layout from "../Layout";

const ThankYou = () => {
  return (
    <Layout>
      <div className="thankYou">
        <h1 className="thankYou__title">
          <Translate id="thank_you.title" />
        </h1>
        <p className="thankYou__caption">
          <Translate id="thank_you.caption" />
        </p>
        <h2 className="thankYou__subTitle">
          <Translate id="thank_you.subTitle" />
        </h2>
      </div>
    </Layout>
  );
};

export default withLocalize(ThankYou);
