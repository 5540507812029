import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AddCircle from "@material-ui/icons/AddCircle";
import Dns from "@material-ui/icons/Dns";
import { Button, Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "./../../../state/actions/authorizeAction";
import { openMenu, closeMenu, clearInsitute } from "./../../../state/actions/institutesPanelAction";
import { Link } from "react-router-dom";
import * as url from "./../../../common/routeTable";

export const Header = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const open = useSelector(state => state.institutesPanel.isOpenMenu);

  const handleDrawerOpen = () => {
    dispatch(openMenu());
  };

  const handleDrawerClose = () => {
    dispatch(closeMenu());
  };

  return (
    <header>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, "header", {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className="header__title">
            <span
              className={clsx({
                [classes.hide]: open
              })}
            >
              ERIS
            </span>
          </Typography>
          <Button color="inherit" onClick={() => dispatch(logout())}>
            Wyloguj
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
      >
        <div className={classes.toolbar}>
          <Box>
            <Typography variant="h6" className="header__title" style={{ color: "black" }} pl={4}>
              ERIS
            </Typography>
          </Box>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Link to={{ pathname: url.InstitutePage }} onClick={() => dispatch(clearInsitute())}>
            <ListItem button>
              <ListItemIcon>
                <AddCircle />
              </ListItemIcon>
              <ListItemText primary="Dodaj instytut" />
            </ListItem>
          </Link>
          <Link to={{ pathname: url.DashboardPage }}>
            <ListItem button>
              <ListItemIcon>
                <Dns />
              </ListItemIcon>
              <ListItemText primary="Instytuty" />
            </ListItem>
          </Link>
        </List>
      </Drawer>
    </header>
  );
};
{
  /* <MailIcon />; */
}
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
}));
