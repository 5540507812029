import React from "react";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunk from "redux-thunk";
import { getReducers } from "./reducers/rootReducer";
import { routerMiddleware, push } from "connected-react-router";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { showError } from "./actions/messageModalAction";
import { createBlacklistFilter } from "redux-persist-transform-filter";
import * as errorTypes from "./../common/errorsType";
import { createBrowserHistory } from "history";
import { Translate } from "react-localize-redux";
import { removeNotFoundCart, getCart } from "./actions/cartAction";
import { RootPage } from "../common/routeTable";

const translationsBlackListFilter = createBlacklistFilter("localize", ["translations"]);

const persistConfig = {
  storage,
  key: "root",
  version: 1,
  transforms: [translationsBlackListFilter],
  blacklist: ["messageModal", "form", "reservations", "articleDescription", "instituteDescription", "relatedArticles"] // navigation will not be persisted
};

function errorMidleware(store) {
  return next => async action => {
    try {
      return await next(action);
    } catch (error) {
      if (error.errorType !== undefined && error.errorType !== errorTypes.FORM_FIELDS) {
        if (error.errorType === errorTypes.NOT_FOUND && error.errorMessage.name && error.errorMessage.name === "Cart") {
          store.dispatch(showError(<Translate id="summary.cart_not_found" />));
          store.dispatch(removeNotFoundCart());
        } else if (error.errorType === errorTypes.NOT_FOUND) {
          store.dispatch(showError(<Translate id="resource_not_found" />));
          store.dispatch(push(RootPage));
        } else if (error.errorType === errorTypes.OVERLAPPED_HOURS) {
          store.dispatch(showError(<Translate id="select_date.overlapped_hour" />));
        } else {
          store.dispatch(showError(error.errorMessage));
        }
        return;
      }

      throw error;
    }
  };
}

const localizeMiddleware = store => next => action => {
  if (action.type === "@@localize/SET_ACTIVE_LANGUAGE") {
    try {
      localStorage.setItem("lang", action.payload.languageCode);
    } catch (err) {
      //do nothing
    }
  }
  try {
    return next(action);
  } catch (error) {
    console.log(error);
  }
};

const refreshCartMiddleware = store => next => action => {
  if (action.type === "@@router/LOCATION_CHANGE") {
    if (store.getState().cart.instituteId && store.getState().cart.cart.id) {
      let langCode = "pl";
      store.getState().localize.languages.forEach(el => {
        if (el.active) {
          langCode = el.code;
          return;
        }
      });
      store.dispatch(
        getCart({
          instituteId: store.getState().cart.instituteId,
          cartId: store.getState().cart.cart.id,
          lang: langCode
        })
      );
    }
  }
  return next(action);
};

export function preloadedState() {
  const history = createBrowserHistory({ basename: "" });
  history.listen(_ => window.scrollTo(0, 0));

  let store = createStore(
    persistReducer(persistConfig, getReducers(history)),
    composeWithDevTools(
      applyMiddleware(localizeMiddleware, refreshCartMiddleware, errorMidleware, thunk, routerMiddleware(history))
    )
  );
  let persistor = persistStore(store);

  return { store, persistor, history };
}
