import React from "react";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { preloadedState } from "./../../state/createStore";
import { ConnectedRouter } from "connected-react-router";
import { Router } from "../Router";
import { LocalizeProvider, withLocalize } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import locale from "../../locale/global.json";
import MessageModal from "./../MessageModal";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";

export const stateManagment = preloadedState();

const App = () => {
  return (
    <Provider store={stateManagment.store}>
      <LocalizeProvider
        store={stateManagment.store}
        initialize={{
          languages: [
            { name: "English", code: "en" },
            { name: "Polski", code: "pl" }
          ],
          translation: locale,
          options: {
            renderInnerHtml: true,
            renderToStaticMarkup,
            defaultLanguage: localStorage.getItem("lang") || "pl"
          }
        }}
      >
        <MessageModal>
          <PersistGate loading={null} persistor={stateManagment.persistor}>
            <ConnectedRouter history={stateManagment.history}>
              <ThemeProvider theme={theme}>
                <Router />
              </ThemeProvider>
            </ConnectedRouter>
          </PersistGate>
        </MessageModal>
      </LocalizeProvider>
    </Provider>
  );
};

const theme = createMuiTheme({
  typography: {
    fontFamily: ["futura-pt"].join(",")
  }
});

export default withLocalize(App);
