import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withLocalize, Translate } from "react-localize-redux";
import RelatedArticle from "./RelatedArticle";
import { CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getRelatedArticles } from "../../../state/actions/relatedArticlesAction";

const RelatedProducts = ({ instituteId, articleId, activeLanguage }) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.relatedArticles);

  useEffect(() => {
    dispatch(
      getRelatedArticles({
        instituteId: instituteId,
        articleId: articleId,
        lang: activeLanguage.code
      })
    );
  }, [instituteId, articleId]);

  return (
    <>
      {state.loading || (state.details && state.details.length > 0) ? (
        <>
          <h2 className="articleDetails__subtitle articleDetails__subtitle--relatedProducts">
            <Translate id="article_details.related_products" />
          </h2>
          <div className="articleDetails__relatedProducts">
            <div className="articleDetails__relatedProductsWrapper">
              {state.loading ? (
                <div className="circularProgress circularProgress--medium">
                  <CircularProgress size={50} height={50} className="circularProgress--dark" />
                </div>
              ) : (
                <div className="articleDetails__relatedBoxWrapper">
                  {state.details.map(el => {
                    return (
                      <RelatedArticle
                        key={el.id}
                        articleId={el.id}
                        articleName={el.name}
                        articleDescription={el.description}
                        articleDuration={el.duration}
                        articlePrice={el.price}
                        instituteId={instituteId}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>{" "}
        </>
      ) : (
        <div />
      )}
    </>
  );
};

export default withLocalize(RelatedProducts);
