import React from "react";
import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";

export const CustomInput = ({ input, placeholder, meta: { touched, error }, type, style, mask, autoComplete }) => {
  let ownStyle = "";
  if (style) {
    ownStyle = style;
  }

  return (
    <div>
      <InputMask {...input} {...mask}>
        {() => (
          <TextField
            helperText={touched && error}
            {...input}
            variant="outlined"
            error={touched && error !== undefined}
            label={placeholder}
            style={{
              width: "100%",
              minHeight: "80px",
              marginTop: 5,
              ...ownStyle
            }}
            mask={mask}
            type={type}
            autoComplete={autoComplete !== undefined ? autoComplete : input.value}
          />
        )}
      </InputMask>
    </div>
  );
};

CustomInput.propTypes = {
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string
};
