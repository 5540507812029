import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { withLocalize, Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { getArticleGroups } from "../../state/actions/reservationAction";
import Groups from "./Groups";
import { CircularProgress } from "@material-ui/core";

const ArticleGroups = (props) => {
  const dispatch = useDispatch();
  let params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state.reservations);
  const [instituteId, setInstituteId] = useState(params.get("instituteId"));

  useEffect(() => {
    setInstituteId(params.get("instituteId"));
  }, [params]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (instituteId && props.activeLanguage.code) {
      dispatch(getArticleGroups({ instituteId: instituteId, lang: props.activeLanguage.code }));
    }
  }, []);

  return (
    <Layout>
      <div className="articleGroups">
        <h1 className="articleGroups__title">
          <Translate id="article_groups.title" />
        </h1>
        <h2 className="articleGroups__subtitle">
          <Translate id="article_groups.subtitle" />
        </h2>
        {state.groupsLoading ? (
          <div className="circularProgress circularProgress--medium">
            <CircularProgress size={50} height={50} className="circularProgress--dark" />
          </div>
        ) : (
          <Groups groups={state.groups} instituteId={instituteId} />
        )}
      </div>
    </Layout>
  );
};

export default withLocalize(ArticleGroups);
// ;
