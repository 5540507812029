import React from "react";
import { withLocalize, Translate } from "react-localize-redux";
import { reduxForm, Field } from "redux-form";
import * as validate from "../../../common/validate";
import { SummaryInput } from "../SummaryInput";
import { SummaryCheckBox } from "../SummaryCheckBox";
import { Grid } from "@material-ui/core";
import { SummaryButton } from "../SummaryButton";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import submitForm from "../../../state/utils/submitForm";
import { confirmCart } from "./../../../state/actions/cartAction";

const CartSummary = ({ handleSubmit, submitting, activeLanguage }) => {
  const cart = useSelector(state => state.cart);
  const dispatch = useDispatch();

  return (
    <div className="summary__clientDataFormWrapper">
      <h1 className="summary__title">
        <Translate id="summary.enter_data" />
      </h1>
      <div className="summary__formWrapper">
        <form
          className="summary__form"
          onSubmit={handleSubmit(values => {
            const data = {
              instituteId: cart.instituteId,
              cartId: cart.cart.id,
              lang: activeLanguage.code,
              body: values
            };
            return submitForm(dispatch(confirmCart(data)));
          })}
        >
          <Field
            name="client.firstName"
            component={SummaryInput}
            type="text"
            placeholder="summary.first_name"
            validate={[validate.required]}
          />
          <Field
            name="client.lastName"
            component={SummaryInput}
            type="text"
            placeholder="summary.last_name"
            validate={[validate.required]}
          />
          <Field
            name="client.email"
            component={SummaryInput}
            type="email"
            placeholder="summary.email"
            validate={[validate.required, validate.emailFormat]}
          />
          <Field
            name="client.phoneNumber"
            component={SummaryInput}
            type="tel"
            placeholder="summary.phone_number"
            validate={[validate.required]}
          />
          <Field
            name="marketingAgreement"
            id="marketingAgreement"
            component={SummaryCheckBox}
            type="checkbox"
            label="summary.marketingAgreement"
          />{" "}
          <Field
            name="privacyPolicyAgreement"
            id="privacyPolicyAgreement"
            component={SummaryCheckBox}
            type="checkbox"
            label="summary.privacyPolicyAgreement"
            validate={[validate.requiredAgreement]}
          />
          <Grid container className="summary__buttonWrapper">
            <SummaryButton className="summary__button" type="submit" submitting={submitting}>
              <Translate id="summary.confirm" />
            </SummaryButton>
          </Grid>
        </form>
      </div>
    </div>
  );
};
export default withLocalize(reduxForm({ form: "confirmCartForm" })(CartSummary));

CartSummary.propTypes = {
  activeLanguage: PropTypes.object.isRequired
};
