export const LoginUrl = "/tokens";
export const InstitutesPanelUrl = "/institutes";
export const GroupDictionaryUrl = "/institutes/groups/dictionary";
export const InstitutesList = "/institutes/groups";
export const ArticleGroups = (instituteId, lang) => `/institutes/${instituteId}/groups?languageSymbol=${lang}`;
export const GroupArticles = (instituteId, groupId, lang) =>
  `/institutes/${instituteId}/articles?languageSymbol=${lang}&groupId=${groupId}`;
export const ArticleDetails = (instituteId, articleId, lang) =>
  `/institutes/${instituteId}/articles/${articleId}?languageSymbol=${lang}`;
export const RelatedArticles = (instituteId, articleId, lang) =>
  `/institutes/${instituteId}/articles/${articleId}/related?languageSymbol=${lang}`;
export const InstituteDetailsPreview = (instituteId, lang) =>
  `/institutes/${instituteId}/preview?languageSymbol=${lang}`;
export const GetAvailableHours = (instituteId, articleId, lang, date) =>
  `institutes/${instituteId}/articles/${articleId}/available-hours?languageSymbol=${lang}&date=${date}`;
export const EmployeeList = (instituteId, articleId, lang, date, hour) =>
  `institutes/${instituteId}/articles/${articleId}/staff-members?languageSymbol=${lang}&date=${date}&hour=${hour}`;
export const CreateCart = (instituteId, lang) => `/institutes/${instituteId}/carts?languageSymbol=${lang}`;
export const AddItemToCart = (instituteId, lang, cartId) =>
  `/institutes/${instituteId}/carts/${cartId}/items?languageSymbol=${lang}`;
export const DeleteItemFromCart = (instituteId, lang, cartId, itemId) =>
  `/institutes/${instituteId}/carts/${cartId}/items/${itemId}?languageSymbol=${lang}`;
export const GetCart = (instituteId, cartId, lang) =>
  `/institutes/${instituteId}/carts/${cartId}?languageSymbol=${lang}`;
export const ConfirmCart = (instituteId, cartId, lang) =>
  `/institutes/${instituteId}/carts/${cartId}?languageSymbol=${lang}`;
export const InstitutesRealId = (id, lang) => `/institutes/id/${id}?languageSymbol=${lang}`;
