import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { withLocalize, Translate } from "react-localize-redux";
import Institutes from "./Institutes";
import Hotels from "./Hotels";
import Partners from "./Partners";
import { getInstitutesList } from "../../state/actions/reservationAction";

const Reservation = () => {
  const dispatch = useDispatch();
  let params = new URLSearchParams(window.location.search);
  const [articleId, setArticleId] = useState(params.get("productId"));
  const state = useSelector((state) => state.reservations);

  useEffect(() => {
    dispatch(getInstitutesList());
  }, []);

  useEffect(() => {
    setArticleId(params.get("productId"));
  }, [params]);

  const checkExist = (value, component) =>
    value === undefined ? <></> : component;
  return (
    <Layout>
      <div className="reservation">
        <h1 className="reservation__title">
          <Translate id="reservation.title" />
        </h1>
        <div className="reservation__institutesWrapper">
          {state?.cosmetics?.cities && (
            <Institutes
              loading={state.loading}
              cities={state?.cosmetics?.cities}
              articleId={articleId}
            />
          )}
          {state?.hotels?.cities && (
            <Hotels
              loading={state.loading}
              cities={state?.hotels?.cities}
              articleId={articleId}
            />
          )}
          {state?.partners?.cities && (
            <Partners
              loading={state.loading}
              cities={state?.partners?.cities}
              articleId={articleId}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default withLocalize(Reservation);
