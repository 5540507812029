import React from "react";
import { withLocalize, Translate } from "react-localize-redux";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { ArticleDetails } from "../../../../common/routeTable";

const RelatedArticle = ({ articleId, articleName, articleDescription, articleDuration, articlePrice, instituteId }) => {
  const dispatch = useDispatch();
  const handleButtonClick = () => {
    dispatch(push(ArticleDetails + `?instituteId=${instituteId}&productId=${articleId}`));
  };
  return (
    <div className="articleDetails__relatedArticle">
      <div className="articleDetails__relatedArticleFirstBoxWrapper">
        <p className="articleDetails__relatedArticleName">{articleName}</p>
        <p className="articleDetails__relatedArticleDescription">{articleDescription}</p>
      </div>
      <p className="articleDetails__relatedArticleName articleDetails__relatedArticleName--articlePrice">
        {articleDuration} min/{articlePrice} <Translate id="article_details.currency" />
      </p>
      <button className="articleDetails__relatedArticleButton buttonGroup__button" onClick={() => handleButtonClick()}>
        <Translate id="article_details.add_button" />
      </button>
    </div>
  );
};
export default withLocalize(RelatedArticle);
