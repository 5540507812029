import React, { useEffect, useState } from "react";
import { withLocalize, Translate } from "react-localize-redux";
import PropTypes from "prop-types";
import ReservationService from "../../../../state/services/ReservationService";
import { Suspender } from "./Suspender";
import Article from "./Article";

const Articles = ({ instituteId, groupId, activeLanguage }) => {
  const [articles, setArticles] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const response = async () => {
      let data = await ReservationService.getGroupArticles({
        instituteId: instituteId,
        groupId: groupId,
        lang: activeLanguage.code
      });
      setArticles(data);
      setLoading(false);
    };

    response();
  }, []);

  return (
    <div className="articleGroups__articleWrapper">
      {loading ? (
        <Suspender />
      ) : (
        <>
          {articles.map(article => {
            return (
              <Article
                key={article.id}
                articleName={article.name}
                articlePrice={article.price}
                articleId={article.id}
                instituteId={instituteId}
                articleDuration={article.duration}
                anyPrice={article.saleAtAnyPrice}
                groupId={groupId}
              />
            );
          })}
          <Translate id="article_groups.disclaimer" />
        </>
      )}
    </div>
  );
};

Articles.propTypes = {};

export default withLocalize(Articles);
