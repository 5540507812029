import * as url from "./urls";
import ApiService from "./ApiService";

class CartService {
  async createCart(data) {
    const response = await ApiService.post(url.CreateCart(data.instituteId, data.lang), data.body);
    if (response.status === 201) {
      return response.data;
    }
  }

  async getCart(data) {
    const response = await ApiService.get(url.GetCart(data.instituteId, data.cartId, data.lang));
    if (response.status === 200) {
      return response.data;
    }
  }

  async addItemtoCart(data) {
    const response = await ApiService.post(url.AddItemToCart(data.instituteId, data.lang, data.cartId), data.body);
    if (response.status === 201) {
      return response.data;
    }
  }

  async removeItemFromCart(data) {
    const response = await ApiService.delete(
      url.DeleteItemFromCart(data.instituteId, data.lang, data.cartId, data.itemId)
    );
    if (response.status === 200) {
      return response.data;
    }
  }

  async confirmCart(data) {
    const response = await ApiService.post(url.ConfirmCart(data.instituteId, data.cartId, data.lang), data.body);
    if (response.status === 200) {
      return true;
    }
  }
}

export default CartService = new CartService();
