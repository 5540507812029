import * as actionsType from "./actionsType";
import ReservationService from "../services/ReservationService";

export const getArticleDetails = data => async dispatch => {
  dispatch(removeDetails());
  const response = await ReservationService.getArticleDetails(data);
  dispatch(setArticleDetails(response));
};

export const removeArticleDetails = data => async dispatch => {
  dispatch(removeDetails());
};

const setArticleDetails = payload => ({
  type: actionsType.SET_DETAILS,
  payload: payload
});

const removeDetails = payload => ({
  type: actionsType.REMOVE_DETAILS,
  payload: payload
});
