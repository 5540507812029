import * as actionsType from "./../actions/actionsType";
import { updateObject } from "./../utils/updateObject";

const initialState = {
  institutesList: undefined,
  loading: true,
  cosmetics: [],
  hotels: [],
  partners: [],
  selectedInstitute: "",
  groups: [],
  groupsLoading: true
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionsType.SUCCESS_GET_INSTITUTES_LIST:
      return successGetInstitutesList(state, payload);
    case actionsType.SELECT_INSTITUTE:
      return SetInstitute(state, payload);
    case actionsType.SUCCESS_GET_ARTICLE_GROUPS:
      return successGetArticleGroups(state, payload);
    default:
      return state;
  }
};

const successGetInstitutesList = (state, payload) => {
  return updateObject(state, {
    institutesList: payload,
    cosmetics: payload[payload.findIndex(type => type.group.id === 1)],
    hotels: payload[payload.findIndex(type => type.group.id === 2)],
    partners: payload[payload.findIndex(type => type.group.id === 3)],
    loading: false
  });
};

const SetInstitute = (state, payload) => {
  return updateObject(state, {
    selectedInstitute: payload
  });
};

const successGetArticleGroups = (state, payload) => {
  return updateObject(state, {
    groups: payload,
    groupsLoading: false
  });
};
