import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogActions, Grid, Typography, Divider, Box } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { closeMessageModal } from "../../state/actions/messageModalAction";
import { CustomButton } from "../UI/CustomButton";
import CloseIcon from "@material-ui/icons/Close";
import { Translate, withLocalize } from "react-localize-redux";

export const MessageModal = ({ children }) => {
  const state = useSelector(state => state.messageModal);
  const [hasMessage, setHasMessage] = useState(state.showModal);
  const dispatch = useDispatch();

  useEffect(() => {
    setHasMessage(state.showModal);
  }, [state]);

  const onClose = () => {
    setHasMessage(false);
    setTimeout(() => {
      dispatch(closeMessageModal());
    }, 100);
  };

  return (
    <>
      {children}
      <Dialog fullWidth maxWidth="xs" onClose={() => onClose()} open={hasMessage}>
        <Box boxShadow={5}>
          <DialogActions className="dialog__header--white">
            <Grid container alignItems="center">
              <Grid item xs={1} />
              <Grid item xs={10}>
                <Typography variant="h5" align="center">
                  <Box component="span" fontWeight="bold" fontStyle="uppercase">
                    {state.isError ? <Translate id="error"></Translate> : <></>}
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <CloseIcon onClick={() => onClose()} style={{ float: "right" }} />
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
        <DialogContent>
          <Box p={3} alignItems="center">
            <Typography align="center">{state.message}</Typography>
          </Box>
        </DialogContent>
        <Divider orientation="horizontal" />
        <DialogActions>
          <Grid container>
            <Grid item xs />
            <Grid item>
              <CustomButton onClick={() => onClose()} className="button--brown">
                <Translate id="close_button" />
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withLocalize(MessageModal);
