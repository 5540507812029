import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Reservation from "./../Reservation";
import Login from "./../Administrator/Login";
import ManagmentInstitute from "../Administrator/ManagmentInstitute";
import { Dashboard } from "./../Administrator/Dashboard";
import * as path from "./../../common/routeTable";
import { ProtectedRoute } from "../../hocs/protectedRoute";
import { useSelector } from "react-redux";
import ThankYou from "../ThankYou";
import ArticleGroups from "../ArticleGroups";
import ArticleDetails from "../ArticleDetails";
import SelectDate from "../SelectDate";
import Summary from "../Summary";
import { LoginRoute } from "../../hocs/loginRoute";

export const Router = () => {
  const state = useSelector(state => state.authorize);
  return (
    <>
      <Switch>
        <Route path={path.RootPage} exact component={Reservation} />
        <Route path={path.ThankYou} exact component={ThankYou} />
        <Route path={path.ArticleGroups} exact component={ArticleGroups} />
        <Route path={path.ArticleDetails} exact component={ArticleDetails} />
        <Route path={path.SelectDate} exact component={SelectDate} />
        <Route path={path.Summary} exact component={Summary} />
        <LoginRoute path={path.LoginPage} exact component={Login} isAuthorize={state.isAuthorize} />
        <ProtectedRoute isAuthorize={state.isAuthorize} path={path.DashboardPage} exact component={Dashboard} />
        <ProtectedRoute
          isAuthorize={state.isAuthorize}
          path={path.InstitutePage}
          exact
          component={ManagmentInstitute}
        />
        <ProtectedRoute
          isAuthorize={state.isAuthorize}
          path={path.InstituteEditPage}
          exact
          component={ManagmentInstitute}
        />
        <Redirect to="/" />
      </Switch>
    </>
  );
};
